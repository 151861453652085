<script>
import Swal from "sweetalert2";
import LienConnected from "../../class_help_me";
import UploadMedias from "vue-upload-drop-medias";
import SaveCompUser from "../../../../Shared/components/SaveCompUser.vue";
export default {
  name: "Enregistrer-Produit",
  components: {
    UploadMedias,
    SaveCompUser,
  },
  data() {
    return {
      idCreateRealEstate: "",
      windowCreateUser: false,
      localisation_gps: "",
      dataObject: "",
      codePropriete: "",
      responseCreateImage: "",
      libelleTypeOperation: "",
      lienUrl: new LienConnected().baseUrl,
      label: "",
      convenience: [],
      listCommune: [],
      filterVilleForCommune: [],
      listVille: [],
      preloader: false,
      listCommodite: [],
      categorieChosen: "",
      ListProprietaire: [],
      appartenanceImmobiliere: "",
      docuPropriete: "",
      personneMaxi: "",
      categorieProduct: "",
      descriptionPropriete: "",
      Listcategorie: [],
      moneyFormat: new Intl.NumberFormat("de-DE"),
      imagePropriete: [],
      labelPropriete: "",
      typePropriete: "",
      costPropriete: "",
      documentPropriete: "",
      roomPropriete: "",
      cautionPropriete: "",
      date_end: "",
      date_entree: "",
      lotPropriete: "",
      areaPropriete: "",
      ilotPropriete: "",
      addressPropriete: "",
      municipality_idPropriete: "",
      ville_idPropriete: "",
      category_idPropriete: "",
      proprietairePropriete: "",
      comoditesPropriete: "",
      TypeOperationImmobiliere: "",
      transactionImmobiliere: "",
      listTypeOperation: [],
      listCommoditeChoosen: [],
      tableauCommoditeChoosen: "",
      listCommoditeGetOut: [],
      fields: [
        {
          convenience_id: "",
          number: "",
        },
      ],
      openAddInfoLocataire: "",
    };
  },
  methods: {
    seeCategorieChoosen(value) {
      this.Listcategorie.forEach((item) => {
        if (item.id == value.target.value) this.categorieChosen = item.label;
      });
      this.ilotPropriete = "";
      this.lotPropriete = "";
      this.typePropriete = "";
      this.roomPropriete = "";
      this.areaPropriete = "";
      this.municipality_idPropriete = "";
      this.personneMaxi = "";
    },
    handleMedias(files) {
      this.imagePropriete = files;
    },

    async helpMeForRenderAllList(route, tableauList) {
      const dataObject = new LienConnected(route, this.$store.state.token);
      let allList = await dataObject.getAllData();
      tableauList = allList.data.data;
      return tableauList;
    },
    async getList() {
      const listCategorie = [];
      const listVille = [];
      const operations = [];
      const listCommune = [];
      const listCommodite = [];
      const ListProprietaire = [];
      this.Listcategorie = await this.helpMeForRenderAllList("categories", listCategorie);
      this.ListProprietaire = await this.helpMeForRenderAllList(
        "proprietaires",
        ListProprietaire
      );
      this.listTypeOperation = await this.helpMeForRenderAllList("types", operations);
      //console.log("this.listTypeOperation", this.listTypeOperation);
      this.listCommodite = await this.helpMeForRenderAllList("comodites", listCommodite);
      this.listCommune = await this.helpMeForRenderAllList("municipalite", listCommune);
      this.listVille = await this.helpMeForRenderAllList("cities", listVille);

      // console.log("this.listCommune",this.listCommune);
    },

    chooseConvenience(e) {
      console.log(e.target.value);
      const itemAtdelete = this.commoditeArray.findIndex(
        (item) => item.key == e.target.value
      );
      this.commoditeArray.splice(itemAtdelete, 1);
      //console.log("new tableau", this.commoditeArray);
    },
    chooseCommodite(e) {
      this.commoditeArray = [];
      console.log(e.target.value);
      this.selectedOptions.forEach((item) => {
        for (const [key, value] of Object.entries(this.selectedNumbers)) {
          if (item == key) {
            this.commoditeArray.push({
              convenience_id: key,
              number: value,
            });
          }
        }
      });
      //console.log("tableua", this.commoditeArray);
    },
    closeViewInfoProspect() {
      this.viewInfoProspect = !this.viewInfoProspect;
    },
    viewInfo() {
      const phoneRegex = /^[0-9]{10}$/;
      const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
      if (phoneRegex.test(this.phoneOneprospect)) {
        this.msgPhoneErr = false;
        if (phoneRegex.test(this.phoneTwoprospect)) {
          this.msgPhoneErr2 = false;
          if (emailRegex.test(this.emailProspect)) {
            this.msgEmailErr = false;
            this.viewInfoProspect = !this.viewInfoProspect;
          } else {
            Swal.fire({
              icon: "error",
              title: "addresse email non valide",
              showConfirmButton: false,
              timer: 1000,
            });
            this.msgEmailErr = true;
          }
        } else {
          Swal.fire({
            icon: "info",
            title: "Numéro de téléphone non valide",
            showConfirmButton: false,
            timer: 1000,
          });
          this.msgPhoneErr2 = true;
        }
      } else {
        Swal.fire({
          icon: "info",
          title: "Numéro de téléphone non valide",
          showConfirmButton: false,
          timer: 1000,
        });
        this.msgPhoneErr = true;
      }
    },
    pushElementInTableau(tableau) {
      let tableauFieldsFilter = [];
      tableau.forEach((item) => {
        if (item.convenience_id.id && item.number) {
          tableauFieldsFilter.push({
            comodity_id: item.convenience_id.id,
            number: item.number,
          });
        }
      });
      return tableauFieldsFilter;
    },
    helpMeIterate(data) {
      data.forEach((item) => {
        data.append("image[]", item);
      });
      return data;
    },
    helpmeCreateProductAndSaveImage(route) {
      const object = new LienConnected(route, this.$store.state.token);
      return object;
    },
    verifForCreateResidence() {
      // console.log("this.costPropriete",this.costPropriete)
      if (
        !this.categorieProduct ||
        !this.labelPropriete ||
        !this.costPropriete ||
        !this.personneMaxi ||
        !this.municipality_idPropriete ||
        !this.roomPropriete ||
        !this.appartenanceImmobiliere ||
        !this.codePropriete
        // !this.proprietairePropriete
      ) {
        Swal.fire({
          icon: "info",
          title: "Veuillez remplir tous les champs.",
          showConfirmButton: true,
        });
      } else {
        this.saveProductRealState("");
        //console.log(this.categorieChosen);
      }
    },
    verifFieldsCreateRealLocationIfClickOnAddLocataire() {
      if (
        !this.categorieProduct ||
        !this.labelPropriete ||
        !this.costPropriete ||
        !this.municipality_idPropriete ||
        !this.TypeOperationImmobiliere ||
        !this.roomPropriete ||
        !this.appartenanceImmobiliere ||
        !this.date_entree
        // !this.proprietairePropriete
      ) {
        Swal.fire({
          icon: "info",
          title: "Veuillez remplir tous les champs.",
          showConfirmButton: true,
        });
      } else {
        this.saveUser(true);
      }
    },
    verifForCreateMaison() {
      if (
        !this.categorieProduct ||
        !this.labelPropriete ||
        !this.costPropriete ||
        !this.municipality_idPropriete ||
        !this.TypeOperationImmobiliere ||
        !this.roomPropriete ||
        !this.appartenanceImmobiliere
        // !this.proprietairePropriete
      ) {
        Swal.fire({
          icon: "info",
          title: "Veuillez remplir tous les champs.",
          showConfirmButton: true,
        });
      } else {
        this.saveProductRealState("");
      }
    },
    verifForCreateTerrain() {
      if (
        !this.categorieProduct ||
        !this.labelPropriete ||
        !this.costPropriete ||
        !this.municipality_idPropriete ||
        !this.lotPropriete ||
        !this.areaPropriete ||
        !this.TypeOperationImmobiliere ||
        !this.documentPropriete ||
        !this.appartenanceImmobiliere
        // !this.proprietairePropriete
      ) {
        Swal.fire({
          icon: "info",
          title: "Veuillez remplir tous les champs.",
          showConfirmButton: true,
        });
      } else {
        this.saveProductRealState("");
      }
    },
    middleWareSaveProduct(categorie) {
      //console.log("categorie", categorie);
      switch (categorie) {
        case "Résidence":
          this.verifForCreateResidence();
          break;
        case "Terrain":
          this.verifForCreateTerrain();
          break;
        case "Maison":
          this.verifForCreateMaison();
          break;
      }
    },
    resetData() {
      this.libelleCommodite = "";
      this.labelPropriete = "";
      this.costPropriete = "";
      this.documentPropriete = "";
      this.roomPropriete = "";
      this.transactionImmobiliere = "";
      this.typePropriete = "";
      this.cautionPropriete = "";
      this.descriptionPropriete = "";
      this.lotPropriete = "";
      this.imagePropriete = [];
      this.areaPropriete = "";
      this.ilotPropriete = "";
      this.personneMaxi = "";
      this.addressPropriete = "";
      this.municipality_idPropriete = "";
      this.categorieProduct = "";
      this.appartenanceImmobiliere = "";
      this.appartenanceImmobiliere = "";
      this.TypeOperationImmobiliere = "";
      this.codePropriete = "";
      this.fields = [
        {
          convenience_id: "",
          number: "",
        },
      ];

      // if (this.categorieChosen == "Terrain"){
      //   this.$router.push("/terrain");
      // } else if (this.categorieChosen == "Résidence"){
      //   this.$router.push("/residence");
      // } else {
      //   if(this.libelleTypeOperation === "Location"){
      //     this.$router.push("/appartement_a_louer");
      //   } else {
      //     this.$router.push("/appartement");
      //   }
      // }
    },
    async saveProductRealState(isAddLocataire) {
      this.preloader = true;
      let tableauCommodite = this.pushElementInTableau(this.fields);

      this.TypeOperationImmobiliere =
        this.categorieChosen == "Résidence" ? 3 : this.TypeOperationImmobiliere;
      let data = new FormData();
      tableauCommodite.forEach((commodite) => {
        data.append("comodites[]", JSON.stringify(commodite));
      });

      data.append("type_id", this.TypeOperationImmobiliere);
      data.append("name", this.labelPropriete);
      data.append("type", this.typePropriete);
      data.append("cost", this.costPropriete);
      data.append("document", this.documentPropriete);
      data.append("room", this.roomPropriete);
      data.append("caution", this.cautionPropriete);
      data.append("localisation_gps", this.localisation_gps);
      data.append("description", this.descriptionPropriete);
      data.append("lot", this.lotPropriete);
      data.append("area", this.areaPropriete);
      data.append("ilot", this.ilotPropriete);
      data.append("adress", this.addressPropriete);
      data.append("person_maxi", this.personneMaxi);
      data.append("municipality_id", this.municipality_idPropriete.id);
      data.append("category_id", this.categorieProduct);
      data.append("code", this.codePropriete);
      data.append(
        "user_id",
        this.appartenanceImmobiliere == "fournisseur"
          ? this.proprietairePropriete.id
          : this.$store.state.user.id
      );
      data.append("registered_by", this.appartenanceImmobiliere);

      //console.log(data);
      try {
        this.preloader = true;
        const dataObjectForCreateReal = this.helpmeCreateProductAndSaveImage(
          "proprietes"
        );

        const responseCreateReal = await dataObjectForCreateReal.sendData(data);

        this.idCreateRealEstate = responseCreateReal.data.data.id;
        console.log("this.idCreateRealEstate", this.idCreateRealEstate);

        if (responseCreateReal.data.status === true) {
          try {
            let propriety_id = responseCreateReal.data.data.id;
           
            let dataForCreateImage = new FormData();
            this.imagePropriete.forEach((item) => {
              dataForCreateImage.append("image[]", item);
            });
            dataForCreateImage.append("propriety_id", propriety_id);
            const dataObjectForCreateImage = this.helpmeCreateProductAndSaveImage(
              "photos"
            );
            const responseCreateImage = await dataObjectForCreateImage.sendData(
              dataForCreateImage
            );
            this.responseCreateImage = responseCreateImage;
            
            if (responseCreateReal.data.status === true) {
              Swal.fire({
                icon: "success",
                title: "Enregistré avec succès.",
                showConfirmButton: false,
                timer: 1500,
              });
              if(isAddLocataire){
                this.handleAssignation(propriety_id,this.proprietairePropriete, isAddLocataire);
              }
              this.getAlarms();
              this.resetData();
            }
          } catch (error) {
            this.preloader = false;
          }
          this.preloader = false;
        }
      } catch (error) {
        this.preloader = false;
      }
    },
    getAlarms() {
      this.$store.dispatch("getAllNew");
      this.$store.dispatch("getAllreservation");
      this.$store.dispatch("getAllVisites");
      this.$store.dispatch("getAllProprieteSold");
    },
    chooseTypeOperation(e) {
      let idOperation = e.target.value;
      this.listTypeOperation.forEach((item) => {
        if (item.id == idOperation) {
          this.libelleTypeOperation = item.label;
        }
      });
      //console.log("this.libelleTypeOperation", this.libelleTypeOperation);
    },
    getVille(e) {
      // console.log("ville_idPropriete",e.id)
      // console.log("ville_idPropriete",e.id)
      this.municipality_idPropriete = "";
      this.filterVilleForCommune = this.listCommune.filter(
        (item) => item.city_id === Number(e.id)
      );
    },
    addField: function () {
      //console.log(this.listCommodite);
      this.fields.push({ convenience_id: "", number: "" });
      //console.log(this.fields);
      this.listCommoditeGetOut = this.listCommodite.filter(
        (item) => item.label !== this.tableauCommoditeChoosen
      );
      if (this.listCommoditeGetOut.length) {
        this.listCommodite = this.listCommoditeGetOut;
        // console.log("this.listCommodite",this.listCommodite)
      }
      // console.log("listCommodite",this.listCommoditeGetOut)
    },
    deleteField: function (element) {
      // console.log("element",element.convenience_id.label)
      const DeleteField = this.fields.findIndex((item) => item === element);
      this.fields.splice(DeleteField, 1);
      if (element.convenience_id.label) {
        this.listCommodite.push(element.convenience_id.label);
      } else {
        return;
      }
    },
    getValue(e) {
      // console.log("getValue",e.label)
      this.tableauCommoditeChoosen = e.label;
      // console.log("this.listCommoditeChoosen",this.listCommoditeChoosen)
    },
    middlewareForCreateProprietaire(payload) {
      if (!payload.documentUtilisateur.length) {
        Swal.fire({
          icon: "info",
          title: "Veuillez renseigner une pièce jointe.",
          showConfirmButton: true,
        });
      } else {
        this.createUtilisateur(payload);
      }
    },
    saveUser(payload) {
      this.$store.dispatch("handleToogleWindowCreateUser", payload);
    },
    putValueInArray(tableau) {
      const tableauBoucle = [];
      tableau.forEach((item) => {
        tableauBoucle.push(item.id);
      });
      return tableauBoucle;
    },
    async handleAssignation(idCreateRealEstate,proprietaire, idUser) {
      const data = {
        proprietaire_id: proprietaire.id,
        client_id: idUser,
        propriety_id: idCreateRealEstate,
        date_entree: this.date_entree,
        date_sortie: this.date_end,
      };
      console.log("handleAssignationDATA", data);

      try {
        const dataObject = new LienConnected("contracts", this.$store.state.token);
        const assignerLocataire = await dataObject.sendData(data);
        const response = assignerLocataire;
        console.log("handleAssignationRESPONSE", response);
      } catch (error) {
        console.log(error);
      }
    },
    async createUtilisateur(userPayload) {
      console.log("userPayload", userPayload);
      this.$store.commit("CHANGE_LOADING",true)
      const profils = this.putValueInArray(userPayload.roleUtilisateur);
      try {
        this.preloaderCreateProprio = true;
        let dataUser = new FormData();
        dataUser.append("lastname", userPayload.nameUtilisateur);
        dataUser.append("firstname", userPayload.prenomsUtilisateur);
        dataUser.append("email", userPayload.emailUtilisateur);
        dataUser.append("password", userPayload.passwordUtilisateur);
        dataUser.append("phone", userPayload.phoneUtilisateur);
        profils.forEach((role) => {
          dataUser.append("roles[]", role);
        });
        userPayload.documentUtilisateur.forEach((doc) => {
          dataUser.append("cni_doc[]", doc);
        });
        const dataObject = new LienConnected("users", this.$store.state.token);
        const createUser = await dataObject.sendData(dataUser);
        const response = createUser;
        console.log("createUtilisateur", createUser);

        if (response.data.status === true) {
          console.log("response.data.data", response.data.data);
          const idUserArgument = response.data.data.id;
          Swal.fire({
            icon: "success",
            title: "Utilisateur enregistré.",
            showConfirmButton: false,
            timer: 1500,
          });
          this.saveProductRealState(idUserArgument);
          this.preloaderCreateProprio = false;
          setTimeout(() => {
            this.saveUser(false);
          }, 1500);
          this.$store.commit("CHANGE_LOADING",false)
          // location.reload();
        }
        if (!response.data.status) {
          Swal.fire({
            icon: "info",
            title: response.data.message.email,
            showConfirmButton: true,
          });
          this.preloaderCreateProprio = false;
          this.$store.commit("CHANGE_LOADING",false)
        }
      } catch (error) {
        console.log(error);
        this.preloaderCreateProprio = false;
        this.$store.commit("CHANGE_LOADING",false)
      }
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
<template>
  <div class="col-lg-9 col-md-12 position-relative">
    <div class="dashboard-wraper">
      <SaveCompUser

        @handleSubmit="middlewareForCreateProprietaire"
      />
      <div class="row text-start">
        <div class="col-lg-12 col-md-12">
          <form>
            <div class="submit-pages_view text-start">
              <div class="form-submit">
                <h2 class="text-light">Enregistrer une propriété</h2>
                <div class="submit-section">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label
                        >Catégorie de la propriéte
                        <span class="text-danger">*</span></label
                      >

                      <select
                        v-model="categorieProduct"
                        class="form-control"
                        @change="seeCategorieChoosen"
                      >
                        <option disabled value="">Sélectionne une catégorie</option>
                        <option
                          v-for="(categorie, index) in Listcategorie"
                          :key="index"
                          :value="categorie.id"
                          :id="categorie.label"
                        >
                          {{ categorie.label }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-6" v-if="categorieChosen">
                      <label class="text-dark"
                        >Nom
                        {{
                          categorieChosen == "Terrain"
                            ? "du terrain"
                            : categorieChosen == "Maison"
                            ? "de la maison"
                            : "de la résidence"
                        }}
                        <span class="text-danger">*</span></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model="labelPropriete"
                        required
                      />
                    </div>
                    <div
                      class="form-group col-md-6"
                      v-if="categorieChosen == 'Résidence'"
                    >
                      <label class="text-dark"
                        >Code d'entrée <span class="text-danger">*</span></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model="codePropriete"
                        required
                      />
                    </div>

                    <div
                      class="form-group col-md-6"
                      v-if="categorieChosen == 'terrain' || categorieChosen == 'Terrain'"
                    >
                      <label>Ilot <span class="text-danger">*</span></label>

                      <input
                        type="text"
                        class="form-control"
                        v-model="ilotPropriete"
                        required
                      />
                    </div>
                    <div
                      class="form-group col-md-6"
                      v-if="categorieChosen == 'terrain' || categorieChosen == 'Terrain'"
                    >
                      <label>Numéro de lot <span class="text-danger">*</span></label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="lotPropriete"
                        required
                      />
                    </div>
                    <div
                      class="form-group col-md-6"
                      v-if="categorieChosen == 'Résidence' || categorieChosen == 'Maison'"
                    >
                      <label>Type de Propriéte <span class="text-danger">*</span></label>

                      <input
                        type="text"
                        class="form-control"
                        v-model="typePropriete"
                        placeholder="villa,duplex etc..."
                        required
                      />
                    </div>

                    <div
                      class="form-group col-md-6"
                      v-if="categorieChosen == 'Maison' || categorieChosen == 'Terrain'"
                    >
                      <label
                        >Type de l'operation <span class="text-danger">*</span></label
                      >
                      <select
                        v-model="TypeOperationImmobiliere"
                        class="form-control"
                        @change="chooseTypeOperation"
                      >
                        <option disabled value="">Veuillez sélectionner</option>
                        <option
                          v-for="item in listTypeOperation"
                          :key="item"
                          :value="item.id"
                        >
                          {{ item.label }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-6" v-if="categorieChosen">
                      <label>Prix <span class="text-danger">*</span></label>
                      <VueNumberFormat
                        class="form-control"
                        v-model:value="costPropriete"
                        :options="{
                          precision: 0,
                          nullValue: false,
                          prefix: '',
                          decimal: ',',
                          thousand: '.',
                          acceptNegative: false,
                          isInteger: false,
                        }"
                      ></VueNumberFormat>
                    </div>

                    <div
                      class="form-group col-md-6"
                      v-if="
                        categorieChosen == 'Maison' && libelleTypeOperation == 'Location'
                      "
                    >
                      <label>Caution (Nombre de mois)</label>

                      <input
                        type="number"
                        min="1"
                        class="form-control"
                        v-model="cautionPropriete"
                      />
                    </div>

                    <div
                      class="form-group col-md-6"
                      v-if="
                        categorieChosen == 'Maison' && libelleTypeOperation == 'Location'
                      "
                    >
                      <label>Date d'entrée <span class="text-danger">*</span></label>
                      <input
                        type="date"
                        :min="new Date().toISOString().slice(0, 10)"
                        class="form-control"
                        v-model="date_entree"
                      />
                    </div>
                    <div
                      class="form-group col-md-6"
                      v-if="
                        categorieChosen == 'Maison' && libelleTypeOperation == 'Location'
                      "
                    >
                      <label>Date de sortie</label>
                      <input
                        :disabled="!date_entree"
                        type="date"
                        :min="date_entree"
                        class="form-control"
                        v-model="date_end"
                      />
                    </div>

                    <div
                      class="form-group col-md-6"
                      v-if="categorieChosen == 'Maison' || categorieChosen == 'Résidence'"
                    >
                      <label>Nombre de pièces <span class="text-danger">*</span></label>

                      <input
                        type="number"
                        min="1"
                        class="form-control"
                        v-model="roomPropriete"
                        required
                      />
                    </div>

                    <div
                      class="form-group col-md-6"
                      v-if="categorieChosen == 'Maison' || categorieChosen == 'Terrain'"
                    >
                      <label>Superficie</label>

                      <input
                        type="number"
                        min="1"
                        class="form-control"
                        v-model="areaPropriete"
                      />
                    </div>
                    <div class="form-group col-md-6" v-if="categorieChosen">
                      <label>Ville <span class="text-danger">*</span></label>

                      <v-select
                        placeholder="Sélectionner une ville"
                        v-model="ville_idPropriete"
                        :searchable="true"
                        :options="listVille"
                        label="name"
                        @option:selected="getVille"
                      >
                        <template>
                          <p>{{ label }}</p>
                        </template>
                      </v-select>
                    </div>
                    <div class="form-group col-md-6" v-if="categorieChosen">
                      <label>
                        {{
                          this.ville_idPropriete.name == "Abidjan"
                            ? "Commune"
                            : "Quartier"
                        }}
                        <span class="text-danger">*</span></label
                      >

                      <v-select
                        :placeholder="
                          this.ville_idPropriete.name == 'Abidjan'
                            ? 'Sélectionner une commune'
                            : 'Sélectionner un quartier'
                        "
                        v-model="municipality_idPropriete"
                        :searchable="true"
                        :options="filterVilleForCommune"
                        label="name"
                      >
                        <template>
                          <p>{{ label }}</p>
                        </template>
                      </v-select>
                    </div>
                    <div class="form-group col-md-6" v-if="categorieChosen">
                      <label>Situé précisément</label>

                      <input
                        type="text"
                        class="form-control"
                        v-model="addressPropriete"
                        required
                      />
                    </div>
                    <div
                      class="form-group col-md-6"
                      v-if="categorieChosen == 'Résidence'"
                    >
                      <label
                        >Nombre de personne autorisées
                        <span class="text-danger">*</span></label
                      >

                      <input
                        type="number"
                        class="form-control"
                        min="1"
                        v-model="personneMaxi"
                        required
                      />
                    </div>
                    <div class="form-group col-md-12" v-if="categorieChosen">
                      <label>Description</label>
                      <textarea
                        class="form-control"
                        required
                        v-model="descriptionPropriete"
                        name=""
                        id=""
                        cols="30"
                        rows="10"
                      >
                      </textarea>
                    </div>

                    <div
                      class="form-group col-md-12"
                      v-if="categorieChosen == 'Résidence' || categorieChosen == 'Maison'"
                    >
                      <div
                        v-for="field in fields"
                        :key="field"
                        class="position-relative row"
                      >
                        <span
                          v-if="field"
                          class="delete badge bg-danger fw-bold"
                          @click="deleteField(field)"
                          >x</span
                        >
                        <div class="col-lg-6">
                          <label :for="field">Commodité</label>
                          <v-select
                            v-model="field.convenience_id"
                            :searchable="true"
                            placeholder="Sélectionner une commodité"
                            :options="listCommodite"
                            label="label"
                            autocomplete="true"
                            @option:selected="getValue"
                          >
                            <template>
                              <p>{{ label }}</p>
                            </template>
                          </v-select>
                        </div>
                        <div class="col-lg-6">
                          <label :for="field">Nombre de la commodité</label>
                          <input
                            :id="field"
                            type="number"
                            min="1"
                            class="form-control"
                            v-model="field.number"
                          />
                        </div>
                      </div>
                      <div class="text-end">
                        <button
                          @click.prevent="addField"
                          class="btn bg-primary w-25 my-3 addField bg-dark text-light"
                        >
                          Ajouter une commodité +
                        </button>
                      </div>
                    </div>
                    <div class="form-group col-md-6" v-if="categorieChosen == 'Terrain'">
                      <label>Document <span class="text-danger">*</span></label>
                      <div class="row container">
                        <section class="col-md-12 circuit">
                          <div class="col-md-12 d-flex align-items-center my-2">
                            <input
                              type="radio"
                              id="ACD"
                              name="fav_language"
                              value="ACD"
                              required
                              v-model="documentPropriete"
                            />
                            <label for="ACD">ACD</label>
                          </div>

                          <div class="col-md-12 d-flex align-items-center my-2">
                            <input
                              type="radio"
                              id="Approbation Ministeriel"
                              name="fav_language"
                              value="ACD en cours"
                              required
                              v-model="documentPropriete"
                            />
                            <label for="Approbation Ministeriel">ACD en cours</label>
                          </div>
                        </section>
                      </div>
                    </div>
                    <div class="form-group col-md-6" v-if="categorieChosen">
                      <label>Appartenance <span class="text-danger">*</span></label>

                      <select v-model="appartenanceImmobiliere" class="form-control">
                        <option disabled value="">Veuillez sélectionner</option>
                        <option value="administrateur">Dexter</option>
                        <option value="fournisseur">Fournisseur</option>
                      </select>
                    </div>
                    <div class="col-lg-6" v-if="categorieChosen">
                      <label class="text-light fw-bold"> Lien de la localisation </label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="localisation_gps"
                      />
                    </div>

                    <div
                      class="form-group col-md-12"
                      v-if="appartenanceImmobiliere == 'fournisseur'"
                    >
                      <label>Proprietaire <span class="text-danger">*</span></label>
                      <v-select
                        v-model="proprietairePropriete"
                        :searchable="true"
                        :options="ListProprietaire"
                        label="firstname"
                      >
                        <template #option="{ firstname, lastname }">
                          <p>{{ lastname }} {{ firstname }}</p>
                        </template>
                      </v-select>
                    </div>
                    <div class="form-group col-md-12 text-start" v-if="categorieChosen">
                      <label
                        >Télécharger des images <span class="text-danger">*</span></label
                      >

                      <UploadMedias
                        uploadMsg="glisser et déposer vos images"
                        @changed="handleMedias"
                      />
                    </div>

                    <div class="text-start mt-3 d-flex gap-3" v-if="categorieChosen">
                      <button
                        v-if="libelleTypeOperation !== 'Location'"
                        class="btn bg-dark text-light bg-dark rounded"
                        @click.prevent="middleWareSaveProduct(categorieChosen)"
                      >
                        Valider
                        <span
                          class="spinner-border spinner-border-sm ms-2"
                          v-show="preloader"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                      <button
                        v-else
                        @click.prevent="
                          verifFieldsCreateRealLocationIfClickOnAddLocataire
                        "
                        class="btn bg-dark text-light bg-dark rounded"
                      >
                        Ajouter un locataire
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.vs__search,
.vs__search:focus {
  padding: 1em 20px !important;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: relative;
  left: 0;
}
.dashboard-wraper {
  height: 100%;
}
.bi-x-circle {
  cursor: pointer;
}

.circuit {
  padding: 0 !important;
}
[type="radio"]:checked + label:after {
  opacity: 0 !important;
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width: 80%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  z-index: 9999;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
}
.submit-pages_view {
  padding: 1em 3em;
}
</style>
