<script>
import DetailPaiement from "./DetailPaiement.vue"
export default {
  name: "ListFactures",
  props: { tableFacture: { type: Array } },
  components:{
    DetailPaiement
  },
  data() {
    return {showModal:false};
  },
  methods:{
    handlePaiement(element,reference){
      console.log("element",element)
      this.$store.dispatch("handleFacturesPaiement",{
        detail:element,
        reference:reference
      })
      this.showModal = true
    }
  }
};
</script>
<template>
  <n-modal v-model:show="showModal">
    <n-card
      style="width: auto; height: 400px; overflow: auto"
      title="Détail"
      :bordered="false"
      size="huge"
      role="dialog"
      aria-modal="true"
    >
      <DetailPaiement />
    </n-card>
  </n-modal>
  <table v-if="tableFacture.length">
    <tr class="thead">
      <th>Date</th>
      <th>Type</th>
      <th>Référence</th>
      <th>Montant (Fcfa)</th>
      <th>Statut</th>
      <th>Période</th>
      <th>Action</th>
    </tr>
    <tr v-for="(item,index) in tableFacture" :key="index">
      <td>
        {{new Date(item.created_at).toLocaleDateString('fr')}}
      </td>
      <td>
        {{item.type}}
      </td>
      <td>
        {{item.reference}}
      </td>
      <td>
        {{item.cost}}
      </td>
      <td>
        {{item.statut}}
      </td>
      <td>
        {{item.date_debut}} {{""}} {{"à"}} {{""}} {{item.date_fin}}
      </td>
      <td>
        <button class="bg-dark text-light"
        @click.prevent="handlePaiement(item.payments,item.reference)"
        >Voir les paiements</button>
      </td>
    </tr>
    
    
  </table>
  <div style="display:flex;padding:2em" 
  v-if="!tableFacture.length">
    Pas de donnée
  </div>
</template>
<style scoped>
th{
  background-color:gray !important;
  color:white;
}
table, th, td {
  border: 1px solid black;
 
  border-collapse: collapse;
}
td{
  padding: .2em;
}
</style>
