<template>
  <router-view/>
</template>

<style>
*{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  font-weight: bold !important;
}

#color-div-red{
  background-color: red !important;
  color:black !important;
}
.vc-disabled{
  color:rgb(174, 174, 174) !important;
}
.layer-axis-x text{
  text-orientation:mixed !important;
}
.listes li{
  padding: 0.5em;
  margin: 0 1em;
  border-radius: 5px;

}
.addField{
  width:auto !important;
}
.badge-total{
  top: -0.5em;
  right: -1em;
  border-radius:100% !important;
}
.bi-eye,.bi-pencil{
  font-size:1.5em;
}
.delete{
  position:absolute;
  right:.5em;
  cursor: pointer;
  width:40px !important;
}
.listes li a{
  color:black !important;
}
.listes {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.085);
  background-color: white;
  padding:1em 0;
}
.color_container,.color_container .form-group .badge{
  background: #d9a509 !important;

}
.submit-pages[data-v-c64d55ce] {
 
  background: #d9a509 !important;
}
.color_container strong,.color_container .form-group h6,.cont .form-group,.color_container .form-group .badge,#MyTableData_info,#MyTableData_paginate{
  color:white !important;
  text-transform: capitalize;
}
.vs__clear{
  display: none !important;
}
.n-upload-dragger{
  color:black !important;
}
.n-upload-trigger.n-upload-trigger--image-card {
  width: 216px !important;
  height: 96px;
}

.color_container .form-group,.cont .form-group[data-v-c64d55ce]{
  background: #111111 !important;
}


.lien {
  text-decoration: none;
  font-size: 1.3em;
   
  color: rgb(218, 216, 216);
}
.lien:hover {
  color: rgb(255, 187, 0);;
}
.color,
.colorHistorique,
.colorNew{
  color: rgb(0, 0, 0) !important;
  border-radius:5px;
  padding:.5em;
  font-weight:bold;
  background-color: rgb(255, 187, 0);
}
.text-dark{
  color:black !important;
}
.container,.ant-picker,.vc-bordered{
  width:100% !important;
}
button {
  transition: transform 0.2s ease-in-out;
}

p::first-letter,.profil_name,h1::first-letter {
  text-transform: capitalize;
}
.profil_name{
  cursor: pointer;
}
#MyTableData3_filter label{
  color:black !important;
}
#MyTableData5_info{
  background-color: white;
}

.n-tabs .n-tab-pane{
  background-color: white;
}
button:active {
  transform: scale(0.95);
}
.text-dark,.bi-x-circle{
  color: black  !important;
}

tr:hover{
  background:rgba(135, 135, 135, 0.946) !important;
  cursor:pointer;
  color:white !important;
}
.dataTables_wrapper .dataTables_filter input {

  background-color: #fff  !important;
  
  color: black  !important;
}

.preloader{
  background-color:rgb(255, 255, 255);
  border-radius:5px;
}
.btn-theme-light-2:active{
  background-color: rgb(104, 105, 105) !important;
  
	padding:16px 50px !important;
    color: white !important;
    border:2px solid black !important;
}
.alert-alarm{
  width:auto !important;
  height:20px !important;
  border-radius:50%;
  background: crimson;
  position:absolute;
  right:2em;
}
.localisation{
  word-break: break-all;
}
.image-a-change{
  width: inherit;
  height: inherit;
  left:0;
  top:0;
  object-fit: cover;
}
.photo-detail{ 
  width:200px;
  height: 200px;
}
.menu-drowp{
  background: rgb(248, 248, 248);
  width: 70%;
  border:1px solid black;
  padding:.8em 1em;
  z-index: 23;
  left:50%;
  transform: translateX(-50%);
  border-radius: .5em;
  color:black;

}
.menu-drowp li{
  cursor: pointer;
  font-size: 1.1em;
}
.bg-primary,.btn-all{
  background-color: rgb(53, 156, 193) !important;
  color: white !important;
  font-weight:600;
  border:2px solid black;
}
.n-switch.n-switch--active .n-switch__rail{
  background-color: rgb(28, 132, 170) !important;
}



.n-input .n-input-wrapper {
  padding: 0.698em !important;
}
.col-lg-9 {
  flex: 0 0 auto;
  width: 75%;
  background: #38383887;
  color: white;
  background-position: center;
  background-size:cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(rgba(110, 107, 107, 0.654), rgba(67, 67, 67, 0.751)),url('./views/dashboard/pages/assets/suaImage.jpeg');
}
#MyTableData_filter label,.form-group  label,.text-light,#MyTableData1_filter label{
  color:white !important;
}
#vs1__listbox,#vs13__listbox,#vs14__combobox,.v-select,#vs14__listbox,#vs17__listbox,#vs2__listbox,#vs5__listbox,.autocomplete,#vs3__listbox,#vs4__listbox,#vs6__listbox,#vs7__listbox,#vs8__listbox,#vs9__listbox,.vs__selected-options{
color:black !important;
}

.vs__dropdown-toggle{
  padding:1em !important;
  background: #f9fafc !important;
}
table.dataTable thead th,table th,tr{
  text-align: center !important;
  background: white !important;
  
}
table.dataTable thead th{
  background: #6f6d6a !important;
  padding: 10px;
  color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.dt-buttons{
  text-align: left !important;
  padding: .5em 0;
}
.bi-x-circle{
  cursor: pointer;
}

button:not(:disabled),[type=button]:not(:disabled){
  background-color: rgb(255, 255, 255);
    border-radius: 5px;
    color:#111111;
    font-weight: bold;
}
.autocomplete-input{
  height:56px;
  font-size:16px;
  box-shadow: none;
padding:.5rem .75rem;
  border:1px solid #e0ecf5;
background-clip: initial;
color: #646e77 !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.bg-primary{
  background-color: rgb(53, 156, 193) !important;

padding:16px 50px;
  color: white !important;
}
.dataTable{
  overflow-x:auto;
}
nav {
  padding: 30px;
}
.vs__dropdown-menu{
background-color:white !important;
}
nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
table{
  overflow-x: auto;
}
h5{
  margin-top: 1em !important;
}

@media (max-width: 990px){
  .col-md-12{
    height:100% !important;
  }
}
</style>
