<script>
// import {utils} from '../../../../utils'
import $ from "jquery";
import Swal from "sweetalert2";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import LienConnected from "../../class_help_me";

import "vue-good-table-next/dist/vue-good-table-next.css";
import SaveCompUser from "../../../../Shared/components/SaveCompUser.vue"
export default {
  name: "liste-personnel",
  components:{
    SaveCompUser
  },
  data() {
    return {
      isDeleteUser: false,
      account: true,
      tabulation: "",
      showDetailBien: false,
      listUser: [],
      listProprietaires: [],
      moneyFormat: new Intl.NumberFormat("de-DE"),
      listClients: [],
      windowCreateUser: false,
      showDetailUserForModify: false,
      showDetailUser: false,
      detailUser: null,
      detailForModifyUser: {
        statut: "",
      },
      photoResidence: [],
      columns: [
        {
          label: "Libelle",
          field: "name",
          tdClass: "text-center",
        },
        {
          label: "Image",
          field: "image",
          tdClass: "text-center",
        },
        {
          label: "Prix (Fcfa)",
          field: "cost",
          tdClass: "text-center",
        },
        {
          label: "Située",
          field: "adress",
          tdClass: "text-center",
        },
        {
          label: "Activée",
          field: "status",
          tdClass: "text-center",
        },
        {
          label: "id",
          field: "id",
          tdClass: "text-center",
          hidden: true,
        },

        {
          label: "Détails",
          field: "detail",
          tdClass: "text-center",
        },
      ],
      columnsClient: [
        {
          label: "date de réservation",
          field: "created_at",
          tdClass: "text-center",
        },
        {
          label: "résidence",
          field: "propriety",
          tdClass: "text-center",
        },
        {
          label: "Code de réservation",
          field: "propriety",
          tdClass: "text-center",
        },
        {
          label: "Entrée",
          field: "date_start",
          tdClass: "text-center",
        },
        {
          label: "Sortie",
          field: "date_end",
          tdClass: "text-center",
        },
        {
          label: "id",
          field: "id",
          tdClass: "text-center",
          hidden: true,
        },
      ],
      roleUser: "",
      preloaderSpinner: false,
      preloader: false,
      preload: false,
      showWindowDelete: false,
      userId: "",
      idUser: "",
      showDetailModeCommodite: false,
      passwordUser: "",
      proofPieces: "",
      dataObject: "",
      lienUrl: new LienConnected().baseUrl,
      cniDoc: [],
      nameUtilisateur: "",
      prenomsUtilisateur: "",
      emailUtilisateur: "",
      phoneUtilisateur: "",
      roleUtilisateur: [],
      documentUtilisateur: [],
      passwordUtilisateur: "",
      confirmPassword: "",
      listRole: [],
      documentUtilisateurForModify: [],
      folder: [],
      detailProduct: null,
      preloaderCreateProprio: false,
      allUsers: [],
    };
  },
  methods: {
    async toogleCompte(value) {
      //console.log("toogleCompteVALUE", !this.detailForModifyUser.statut);
      let data = {
        status: !!this.detailForModifyUser.statut,
      };
      //console.log("DATA", data);
      const dataObject = new LienConnected("Desactiver", this.$store.state.token);
      const response = await dataObject.modifyDataWithPost(data, value);
      if (response.data.status === true) {
        //console.log(response);
        this.listUser.forEach((item) => {
          if (item.id == value) {
            item.statut = !!this.detailForModifyUser.statut;
          }
        });
        Swal.fire({
          icon: "success",
          title: data.status === true ? "compte Activé" : "compte Désactivé",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async helpMeForRenderAllList(route) {
      this.preloader = true;
      const dataObject = new LienConnected(route, this.$store.state.token);
      let allList = await dataObject.getAllData();
      if (allList.data.status === true) {
        this.preloader = false;
        return allList.data.data;
      }
    },

    async getAllList() {
      const roles = await this.helpMeForRenderAllList("roles");
      this.allUsers = await this.helpMeForRenderAllList("users");
      //  console.log("this.allUsers",this.allUsers)
      this.listRole = roles.filter((item) => item.label !== "Client");
      this.allUsers.forEach((item) => {
        if (item.roles.some((item) => item.label == "Client")) {
          this.listClients.push(item);
        }
        if (item.roles.some((item) => item.label == "Propriétaire")) {
          this.listProprietaires.push(item);
        }
        if (
          item.roles.some(
            (item) => item.label != "Propriétaire" && item.label != "Client"
          )
        ) {
          this.listUser.push(item);
        }
      });
      setTimeout(function () {
        let printCounter = 0;
        $("#MyTableData,#MyTableData1,#MyTableData2").DataTable({
          pagingType: "full_numbers",
          pageLength: 10,
          processing: true,
          dom: "Bfrtip",
          buttons: [
            {
              extend: "copy",
              text: "Copier les données",
              messageTop: function () {
                printCounter++;

                if (printCounter === 1) {
                  Swal.fire({
                    icon: "success",
                    title: "Les données ont été copiées dans le presse-papiers.",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                } else {
                  Swal.fire({
                    icon: "info",
                    title: "Les données ont déjà été copiées dans le presse-papiers.",
                    showConfirmButton: true,
                  });
                }
              },
            },
            {
              extend: "csv",
              text: "Exporter en fichier excel",
              fieldSeparator: ";",
              fieldBoundary: '"',
              bom: true,
              exportOptions: {
                columns: [0],
              },
            },
            {
              extend: "print",
              text: "Imprimer les données",
            },
          ],
          order: [],
          language: {
            décimal: "",
            emptyTable: "Aucune donnée disponible dans le tableau",
            infoEmpty: "Showing 0 to 0 of 0 entries",
            info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
            infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
            infoPostFix: "",
            thousands: ",",
            lengthMenu: "Afficher les entrées du _MENU_",
            loadingRecords: "Loading...",
            processing: "Processing...",
            search: "Chercher :",
            stateSave: true,
            zeroRecords: "Aucun enregistrement correspondant trouvé",
            paginate: {
              first: "Premier",
              last: "Dernier",
              next: "Suivant",
              previous: "Précédent",
            },
            aria: {
              sortAscending: ": activate to sort column ascending",
              sortDescending: ": activate to sort column descending",
            },
          },
        });
      }, 10);
    },

    viewDetails(id) {
      this.cniDoc = [];
      let photo = [];

      this.allUsers.forEach((item) => {
        if (item.id === id) {
          this.detailUser = item;
          photo = item.cni_doc ? JSON.parse(item.cni_doc) : [];
        }
      });

      photo.forEach((el) => {
        this.cniDoc.push(
          this.lienUrl.replace("/api/", "/") + "public/public/image/" + el
        );
      });

      this.idUser = id;
      this.showDetailUser = !this.showDetailUser;
    },
    closeWindowDetailCommodite() {
      this.showDetailModeCommodite = !this.showDetailModeCommodite;
    },

    wantDeleteCommodite(id) {
      this.showWindowDelete = !this.showWindowDelete;
      this.userId = id;
    },

    closeWindowDetail() {
      this.showDetailUser = !this.showDetailUser;
    },
    createStatut(item) {
      if (item.status) {
        return true;
      }
      if (!item.status) {
        return false;
      }
    },
    showWindowForModifyInfoUser(id, liste) {
      this.idUser = id;
      liste.forEach((item) => {
        if (item.id === id) {
          this.detailForModifyUser = item;
        }
      });
      this.showDetailUserForModify = !this.showDetailUserForModify;
    },
    closeWindowModifyInfoUser() {
      this.showDetailUserForModify = !this.showDetailUserForModify;
    },
    saveUser() {
      this.windowCreateUser = !this.windowCreateUser;
      this.$store.dispatch('handleToogleWindowCreateUser',this.windowCreateUser) 
    },
    handleMedias(files) {
      this.documentUtilisateur = files;
    },
    handleMediasModify(files) {
      this.documentUtilisateurForModify = files;
    },
    boucleInArray(item) {
      return item.id == 3;
    },
    middlewareForCreateProprietaire(payload) {
      if (!payload.documentUtilisateur.length) {
        Swal.fire({
          icon: "info",
          title: "Veuillez renseigner une pièce jointe.",
          showConfirmButton: true,
        });
      } else {
        this.createUtilisateur(payload);
      }
    },
    putValueInArray(tableau) {
      const tableauBoucle = [];
      tableau.forEach((item) => {
        tableauBoucle.push(item.id);
      });
      return tableauBoucle;
    },
    async createUtilisateur(userPayload) {
      const profils = this.putValueInArray(userPayload.roleUtilisateur);
      try {
        this.preloaderCreateProprio = true;

        let dataUser = new FormData();
        dataUser.append("lastname", userPayload.nameUtilisateur);
        dataUser.append("firstname", userPayload.prenomsUtilisateur);
        dataUser.append("email", userPayload.emailUtilisateur);
        dataUser.append("password", userPayload.passwordUtilisateur);
        dataUser.append("phone", userPayload.phoneUtilisateur);
        profils.forEach((role) => {
          dataUser.append("roles[]", role);
        });
        userPayload.documentUtilisateur.forEach((doc) => {
          dataUser.append("cni_doc[]", doc);
        });
        const dataObject = new LienConnected("users", this.$store.state.token);
        const createUser = await dataObject.sendData(dataUser);
        const response = createUser;
        // console.log("const createUser", createUser);
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Utilisateur enregistré.",
            showConfirmButton: false,
            timer: 1500,
          });
          this.preloaderCreateProprio = false;
          setTimeout(() => {
            this.saveUser();
          }, 1500);
          location.reload();
        }
        if (!response.data.status) {
          Swal.fire({
            icon: "info",
            title: response.data.message.email,
            showConfirmButton: true,
          });
          this.preloaderCreateProprio = false;
        }
      } catch (error) {
        console.log(error);
        this.preloaderCreateProprio = false;
      }
    },
    async modifyUtilisateur(idUser) {
      try {
        //console.log("idUser", idUser);

        let role = [];
        this.detailForModifyUser.roles.forEach((item) => {
          role.push(item.id);
        });
        //console.log("role", role);
        let dataUser = new FormData();
        dataUser.append("lastname", this.detailForModifyUser.lastname);
        dataUser.append("firstname", this.detailForModifyUser.firstname);
        dataUser.append("email", this.detailForModifyUser.email);
        dataUser.append("phone", this.detailForModifyUser.phone);
        this.documentUtilisateurForModify.forEach((doc) => {
          dataUser.append("cni_doc[]", doc);
        });
        role.forEach((role) => {
          dataUser.append("roles[]", role);
        });
        const dataObject = new LienConnected("updateusers", this.$store.state.token);
        const createUser = await dataObject.modifyDataWithPost(dataUser, idUser);
        const response = createUser;
        //console.log("response", response);
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Information modifiée avec succès.",
            showConfirmButton: false,
            timer: 1500,
          });

          setTimeout(() => {
            this.closeWindowModifyInfoUser();
          }, 1500);
        }
      } catch (error) {
        this.preloader = false;
      }
    },
    helpMeIterate(data, folder) {
      let photo = [];
      data.forEach((el) => {
        photo.push(this.lienUrl.replace("/api/", "/") + folder + el);
      });
      return photo;
    },
    putImageInArray(arrayPicture) {
      let compteur = 0;
      let images = [];
      while (compteur < arrayPicture.length) {
        images.push(
          this.lienUrl.replace("/api/", "/") +
            "public/public/image/" +
            arrayPicture[compteur].image
        );
        compteur++;
      }
      return images;
    },
    voirDetailBien(id) {
      this.showDetailBien = !this.showDetailBien;

      this.detailUser.biens.forEach((item) => {
        if (item.id == id) {
          this.photoResidence = this.putImageInArray(item.photos);
          this.detailProduct = item;
        }
      });
      //console.log(id);
    },
    verifyIfRoleInclus(role) {
      const { roles } = JSON.parse(localStorage.getItem("user"));
      return roles.some((item) => item.label === role);
    },
    async userDelete() {
      try {
        const dataObject = new LienConnected("users", this.$store.state.token);
        const deleteUser = await dataObject.deleteData(this.idUser);
        if (deleteUser.data.status) {
          const objetATdelete = this.listUser.findIndex(
            (item) => item.id === this.idUser
          );
          this.listUser.splice(objetATdelete, 1);
          // console.log("objetATdelete",objetATdelete)
          // console.log("this.listUser",this.listUser)
          this.isDeleteUser = false;
          Swal.fire({
            icon: "success",
            title: deleteUser.data.message,
            showConfirmButton: true,
          });
        }
        console.log("deleteUser", deleteUser);
      } catch (e) {
        console.log(e);
      }
    },
    handleUserId(userIdDelete) {
      this.isDeleteUser = true;
      this.idUser = userIdDelete;
    },
  },
  created() {
    this.getAllList();
    this.tabulation = this.verifyIfRoleInclus("Commercial") ? "clients" : "allUser";
  },
};
</script>
<template>
  <div class="conteneur_detail" v-if="showDetailBien">
    <div class="content position-relative">
      <div class="ecriteau text-start d-flex justify-content-between align-items-center">
        <h4>Détail de la propriété</h4>
        <i class="bi bi-x-circle h1" @click="showDetailBien = !showDetailBien"></i>
      </div>
      <div class="text-start px-3">
        <div class="submit-pages text-start">
          <div class="row container cont">
            <div class="form-group col-md-12 col-sm-12">
              <strong class="text-light">Résidence</strong>
              <h6 class="p-0 m-0 text-light">{{ detailProduct.name }}</h6>
              <strong class="text-light">Prix (Fcfa) </strong>
              <h6 class="p-0 m-0 text-light">
                {{ moneyFormat.format(detailProduct.cost) }}
              </h6>

              <strong class="text-light">Situé précisément</strong>
              <h6 class="p-0 m-0 text-light">{{ detailProduct.adress }}</h6>
              <strong class="text-light" v-if="detailProduct.description"
                >Description</strong
              >
              <h6 class="p-0 m-0 text-light">
                {{ detailProduct.description }}
              </h6>
              <strong class="d-block text-light">Commoditées</strong>
              <span
                v-for="(item, index) in detailProduct.comodites"
                :key="index"
                class="d-flex"
              >
                <b class="text-dark">{{ item.pivot.number }}</b>
                <b class="text-dark mx-2">
                  {{ item.label }}
                </b>
              </span>

              <strong class="text-light">Galérie</strong>
              <MazGallery
                v-if="photoResidence.length"
                :images="photoResidence"
                :height="400"
              />
              <h5 v-else class="text-light">Pas de photos</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-9 col-md-12">
    <div class="dashboard-wraper">
      <SaveCompUser
      @handleSubmit="middlewareForCreateProprietaire"
      />
      <!-- <div class="conteneur_modify" v-if="windowCreateUser">
        <div class="content position-relative">
          <div
            class="ecriteau text-start d-flex justify-content-between align-items-center"
          >
            <h4>Enregistrer un utilisateur</h4>
            <i class="bi bi-x-circle close h1" @click.prevent="saveUser"></i>
          </div>
          <div class="col-lg-12 col-md-12 container_color">
           
              <div class="submit-pages text-start">
                <div>
                  <div class="submit-section">
                    <div class="row">
                      <div class="form-group col-md-6">
                        <label class="text-dark"
                          >Nom <span class="text-danger">*</span></label
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="nameUtilisateur"
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label class="text-dark"
                          >Prénoms <span class="text-danger">*</span></label
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="prenomsUtilisateur"
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="text-dark"
                          >Email <span class="text-danger">*</span></label
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="emailUtilisateur"
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="text-dark"
                          >Téléphone <span class="text-danger">*</span></label
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="phoneUtilisateur"
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="text-dark"
                          >Mot de passe
                          <span class="text-danger">*</span></label
                        >
                        <span
                          class="text-danger mx-2"
                          v-if="
                            confirmPassword &&
                            passwordUtilisateur != confirmPassword
                          "
                          >Mot de passe incorrect</span
                        >
                        <input
                          type="password"
                          class="form-control"
                          v-model="passwordUtilisateur"
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="text-dark"
                          >Confirmation du Mot de passe
                          <span class="text-danger">*</span></label
                        >
                        <input
                          type="password"
                          class="form-control"
                          v-model="confirmPassword"
                        />
                      </div>
                      <div class="form-group col-md-6">
                          <label class="text-dark">Role</label>
                       
                          <v-select  v-model="roleUtilisateur" :searchable="true"
                          :options="listRole" multiple label="label">
                             <template>
                               <p>{{label}}</p>
                             </template>
                           </v-select>
                        </div>
                      <div class="form-group col-md-6 text-start">
                        <label class="text-dark"
                          >Pièces jointes
                          <span class="text-danger">*</span></label
                        >
                        <UploadMedias
                          uploadMsg="glisser et déposer vos images"
                          @changed="handleMedias"
                        />
                      </div>

                      <div
                        class="text-start mt-3"
                        v-if="
                          passwordUtilisateur &&
                          emailUtilisateur &&
                          nameUtilisateur &&
                          prenomsUtilisateur
                        "
                      >
                        <button
                          class="btn bg-dark text-light rounded"
                          @click.prevent="middlewareForCreateProprietaire"
                        >
                          Créer
                          <span
                            class="spinner-border spinner-border-sm ms-2"
                            v-show="preloaderCreateProprio"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          
          </div>
        </div>
      </div> -->

      <div class="conteneur_modify" v-if="showDetailUserForModify">
        <div class="content position-relative">
          <div
            class="ecriteau text-start d-flex justify-content-between align-items-center"
          >
            <h4>Modifier les informations</h4>
            <i
              class="bi bi-x-circle close h1"
              @click.prevent="closeWindowModifyInfoUser"
            ></i>
          </div>
          <div class="col-lg-12 col-md-12">
            <form>
              <div class="submit-pages text-start">
                <div class="form-submit">
                  <div class="submit-section">
                    <div class="row">
                      <div class="form-group col-md-6">
                        <label class="text-dark">Nom</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="detailForModifyUser.lastname"
                          disabled
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label class="text-dark">Prénoms</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="detailForModifyUser.firstname"
                          disabled
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="text-dark">Email</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="detailForModifyUser.email"
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="text-dark">Téléphone</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="detailForModifyUser.phone"
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="text-dark">Rôle</label>

                        <v-select
                          v-model="detailForModifyUser.roles"
                          :searchable="true"
                          :options="listRole"
                          multiple
                          label="label"
                        >
                          <template>
                            <p>{{ label }}</p>
                          </template>
                        </v-select>
                      </div>
                      <div class="form-group col-md-6">
                        <label class="text-dark d-block">Pièce jointe</label>
                       
                        <n-image
                        width="100"
                        :src="this.lienUrl.replace('/api/', '/') +
                        'public/public/image/' + JSON.parse(detailForModifyUser.cni_doc)[0]"
                        v-if="detailForModifyUser.cni_doc"
                      />
                      <span v-else>Pas de pièce.</span>
                      </div>
                      <div class="form-group col-md-6 text-start">
                        <label class="text-dark">changer la pièce jointe</label>
                        <UploadMedias
                          uploadMsg="glisser et déposer vos images"
                          @changed="handleMediasModify"
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="text-dark"
                          >Activer/Désactiver le compte</label
                        >
                        <n-space>
                          <n-switch
                            v-model:value="detailForModifyUser.statut"
                            @change="toogleCompte(detailForModifyUser.id)"
                          />
                        </n-space>
                      </div>

                      <div class="text-start mt-3">
                        <button
                          class="btn bg-dark text-light rounded"
                          @click.prevent="
                            modifyUtilisateur(detailForModifyUser.id)
                          "
                        >
                          Modifier
                          <span
                            class="spinner-border spinner-border-sm ms-2"
                            v-show="preloader"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="conteneur_modify" v-if="showDetailUser && detailUser">
        <div class="content position-relative color_container">
          <div
            class="ecriteau text-start d-flex justify-content-between align-items-center"
          >
            <h4>
              Détail de : {{ detailUser.lastname }} {{ detailUser.firstname }}
            </h4>
            <i class="bi bi-x-circle h1" @click.prevent="closeWindowDetail"></i>
          </div>
          <div class="text-start px-3">
            <div class="submit-pages text-start">
              <div class="row container cont">
                <div class="form-group col-md-12 col-sm-12">
                  <div>
                    <h5 class="badge bg-dark">Info utilisateur</h5>
                  </div>
                  <div class="text-center d-flex justify-content-center gap-2">
                    <h4 v-for="(item, index) in detailUser.roles" :key="index">
                      <span class="badge bg-secondary">{{ item.label }}</span>
                    </h4>
                  </div>
                  <div class="row">
                    <div class="col-md-12 col-sm-12">
                      <strong>Nom</strong>
                      <h6 class="p-0 m-0">{{ detailUser.lastname }}</h6>
                      <strong>Prénoms</strong>
                      <h6 class="p-0 m-0">{{ detailUser.firstname }}</h6>
                      <strong>Email</strong>
                      <h6 class="p-0 m-0">{{ detailUser.email }}</h6>
                      <strong>Contact</strong>
                      <h6 class="p-0 m-0">{{ detailUser.phone }}</h6>
                      <strong>Pièces jointes</strong>
                      <div class="my-3">
                        <MazGallery :images="cniDoc" :height="400" />
                      </div>
                      <span v-if="!cniDoc">Pas d'images</span>
                    </div>
                    <div
                      class="col-md-12 col-sm-12 my-5"
                      v-if="
                        detailUser.roles.some(
                          (item) => item.label === 'Propriétaire'
                        )
                      "
                    >
                      <strong class="my-5">Biens</strong>
                      <vue-good-table
                        :columns="columns"
                        :rows="detailUser.biens"
                        :search-options="{
                          enabled: true,
                          placeholder: 'Recherche',
                        }"
                        :pagination-options="{
                          enabled: true,
                          mode: 'pages',
                          perPage: 10,
                          dropdownAllowAll: false,
                          rowsPerPageLabel: 'Rangée par page',
                        }"
                      >
                        <template #table-row="props">
                          <div v-if="props.column.label == 'Image'">
                            <n-image
                              width="50"
                              :src="
                                this.lienUrl.replace('/api/', '/') +
                                'public/public/image/' +
                                props.row.photos[0].image
                              "
                              v-if="props.row.photos.length"
                            />
                            <span class="badge bg-danger" v-else>x</span>
                          </div>

                          <div v-if="props.column.label == 'Détails'">
                            <em
                              class="bi bi-eye"
                              @click.prevent="voirDetailBien(props.row.id)"
                            ></em>
                          </div>
                        </template>
                      </vue-good-table>
                    </div>
                    <div
                      class="col-md-12 col-sm-12 my-5"
                      v-if="
                        detailUser.roles.some((item) => item.label === 'Client')
                      "
                    >
                      <strong>Réservations</strong>
                      <vue-good-table
                        :columns="columnsClient"
                        :rows="detailUser.reservations"
                        :search-options="{
                          enabled: true,
                          placeholder: 'Recherche',
                        }"
                        :pagination-options="{
                          enabled: true,
                          mode: 'pages',
                          perPage: 10,
                          dropdownAllowAll: false,
                          rowsPerPageLabel: 'Rangée par page',
                        }"
                      >
                        <template #table-row="props">
                          <div
                            v-if="props.column.label == 'Code de réservation'"
                          >
                            <span class="badge bg-danger">{{
                              props.row.code
                            }}</span>
                          </div>

                          <div v-if="props.column.label == 'résidence'">
                            <span v-if="props.row.propriety">
                              {{ props.row.propriety.name }}
                            </span>
                          </div>
                          <div
                            v-if="props.column.label == 'date de réservation'"
                          >
                            <span>
                              {{
                                new Date(
                                  props.row.created_at
                                ).toLocaleDateString("fr")
                              }}
                            </span>
                          </div>
                          <div v-if="props.column.label == 'Entrée'">
                            <span>
                              {{
                                new Date(
                                  props.row.date_start
                                ).toLocaleDateString("fr")
                              }}
                            </span>
                          </div>
                          <div v-if="props.column.label == 'Sortie'">
                            <span>
                              {{
                                new Date(props.row.date_end).toLocaleDateString(
                                  "fr"
                                )
                              }}
                            </span>
                          </div>
                        </template>
                      </vue-good-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="form-submit position-relative"
        :class="preloader ? 'pre' : 'null'"
      >
        <div id="preloader" class="position-absolute" v-show="preloader">
          <div class="preloader"><span></span><span></span></div>
        </div>
        <h2 class="text-start text-light">Liste des utilisateurs</h2>
        <ul class="d-flex justify-content-start align-items-center px-5 listes">
          <li @click.prevent="tabulation = 'allUser'" 
          v-if="!this.verifyIfRoleInclus('Commercial')"
          >
            <a
              href="#"
              :class="{ color: tabulation == 'allUser' }"
              class="lien position-relative"
              >Personnels
              <span
                v-show="tabulation == 'allUser'"
                class="badge bg-danger badge-total position-absolute"
                >{{ listUser.length }}</span
              >
            </a>
          </li>
          <li @click.prevent="tabulation = 'clients'">
            <a
              href="#"
              :class="{ color: tabulation == 'clients' }"
              class="lien position-relative"
              >Clients
              <span
                v-show="tabulation == 'clients'"
                class="badge bg-danger badge-total position-absolute"
                >{{ listClients.length }}</span
              >
            </a>
          </li>
          <li class="mx-3" @click.prevent="tabulation = 'Propriétaires'">
            <a
              href="#"
              :class="{ colorNew: tabulation == 'Propriétaires' }"
              class="lien position-relative"
              >Fournisseurs

              <span
                v-show="tabulation == 'Propriétaires'"
                class="badge bg-danger badge-total position-absolute"
                >{{ listProprietaires.length }}</span
              >
            </a>
          </li>
        </ul>
        <div class="text-end" 
        v-if="!this.verifyIfRoleInclus('Commercial')"
        >
          <button
            class="btn btn-all m-2 bg-dark text-light fw-bold"
            @click.prevent="saveUser"
          >
            Créer un utilisateur
          </button>
        </div>
      </div>
      <div v-if="isDeleteUser" class="conteneur-is-delete">
        <div class="contenue-is-delete">
          <h5>Voulez-vous supprimer l'utilisateur ?</h5>
          <div>
            <button class="btn bg-dark text-light mx-2"
            @click.prevent="userDelete"
            >Confirmer</button>
            <button  class="btn bg-danger mx-2"
            @click="isDeleteUser = false"
            >Annuler</button>
          </div>
        </div>
      </div>
 
      <section v-show="tabulation == 'allUser'">
        <div class="col-sm-12 dataTable" v-if="!preloader">
          <table id="MyTableData" class="table text-center table-responsive">
            <thead>
              <tr class="text-center">
                <th class="bg-light text-center">Nom & prénoms</th>
                <th class="bg-light text-center">Email</th>
                <th class="bg-light text-center">Téléphone</th>
                <th class="bg-light text-center">Profil</th>
                <th class="bg-light text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user, index) in listUser" :key="index">
                <td class="profil_name" @click.prevent="viewDetails(user.id)">
                  {{ user.firstname }} {{ user.lastname }}
                </td>
                <td @click.prevent="viewDetails(user.id)">{{ user.email }}</td>
                <td @click.prevent="viewDetails(user.id)">{{ user.phone }}</td>
                <td @click.prevent="viewDetails(user.id)">
                  <span v-for="(role, index) in user.roles" :key="index">
                    <span
                      class="badge mx-1"
                      :class="
                        role.label == 'Manager'
                          ? 'bg-danger'
                          : role.label == 'Client'
                          ? 'bg-info'
                          : 'btn-all'
                      "
                      >{{ role.label }}</span
                    >
                  </span>
                </td>

                <td class="text-center">
                  <span
                    class="d-flex justify-content-center align-items-center"
                  >
                    <i
                      class="bi bi-eye mx-2"
                      @click.prevent="viewDetails(user.id)"
                    ></i>
                    <i
                      class="bi bi-pencil mx-2"
                      
                      @click.prevent="showWindowForModifyInfoUser(user.id,listUser)"
                    ></i>
                    <i
                      class="bi bi-trash mx-2"
                      @click.prevent="handleUserId(user.id)"
                    ></i>
                  </span>
                  
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <section v-show="tabulation == 'clients'">
        <div class="col-sm-12 dataTable" v-if="!preloader">
          <table id="MyTableData1" class="table text-center table-responsive">
            <thead>
              <tr class="text-center">
                <th class="bg-light text-center">Nom & prénoms</th>
                <th class="bg-light text-center">Email</th>
                <th class="bg-light text-center">Téléphone</th>
                <th class="bg-light text-center">Profil</th>
                <th class="bg-light text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user, index) in listClients" :key="index">
                <td class="profil_name" @click.prevent="viewDetails(user.id)">
                  {{ user.firstname }} {{ user.lastname }}
                </td>
                <td @click.prevent="viewDetails(user.id)">{{ user.email }}</td>
                <td @click.prevent="viewDetails(user.id)">{{ user.phone }}</td>
                <td @click.prevent="viewDetails(user.id)">
                  <span v-for="(role, index) in user.roles" :key="index">
                    <span
                      class="badge mx-1"
                      :class="
                        role.label == 'Admin'
                          ? 'bg-danger'
                          : role.label == 'Client'
                          ? 'bg-info'
                          : 'btn-all'
                      "
                      >{{ role.label }}</span
                    >
                  </span>
                </td>

                <td class="text-center">
                  <span
                    class="d-flex justify-content-center align-items-center"
                  >
                    <i
                      class="bi bi-eye mx-2"
                      @click.prevent="viewDetails(user.id)"
                    ></i>
                    <i
                      class="bi bi-pencil mx-2"
                      
                      @click.prevent="showWindowForModifyInfoUser(user.id,listClients)"
                    ></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section v-show="tabulation == 'Propriétaires'">
        <div class="col-sm-12 dataTable" v-if="!preloader">
          <table id="MyTableData2" class="table text-center table-responsive">
            <thead>
              <tr class="text-center">
                <th class="bg-light text-center">Nom & prénoms</th>
                <th class="bg-light text-center">Email</th>
                <th class="bg-light text-center">Téléphone</th>
                <th class="bg-light text-center">Profil</th>
                <th class="bg-light text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user, index) in listProprietaires" :key="index">
                <td class="profil_name" @click.prevent="viewDetails(user.id)">
                  {{ user.firstname }} {{ user.lastname }}
                </td>
                <td @click.prevent="viewDetails(user.id)">{{ user.email }}</td>
                <td @click.prevent="viewDetails(user.id)">{{ user.phone }}</td>
                <td @click.prevent="viewDetails(user.id)">
                  <span v-for="(role, index) in user.roles" :key="index">
                    <span
                      class="badge mx-1"
                      :class="
                        role.label == 'Admin'
                          ? 'bg-danger'
                          : role.label == 'Client'
                          ? 'bg-info'
                          : 'btn-all'
                      "
                      >{{ role.label }}</span
                    >
                  </span>
                </td>

                <td class="text-center">
                  <span
                    class="d-flex justify-content-center align-items-center"
                  >
                    <i
                      class="bi bi-eye mx-2"
                      @click.prevent="viewDetails(user.id)"
                    ></i>
                    <i
                      class="bi bi-pencil mx-2"
                      
                      @click.prevent="showWindowForModifyInfoUser(user.id,listProprietaires)"
                    ></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
  </div>
</template>
<style scoped>
.container_color {
  background: #d9a509 !important;
}
.my_photo_profil {
  border-radius: 100%;
  width: 80px;
  height: 80px;
}

#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: rgba(244, 242, 242, 0);
  display: flex;
  justify-content: center;
  place-items: center;
}
.bg-danger,
.bg-danger:hover {
  color: white !important;
}
h6 {
  margin: 0 0 1em 0 !important;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: relative;
  left: 0;
}
[type="radio"]:checked + label:after {
  opacity: 0 !important;
}
.circuit {
  padding: 0 !important;
}
.card {
  padding: 1em 3em;
}
.bg-primary {
  background-color: rgb(55, 137, 167) !important;
  border: 2px solid rgb(55, 137, 167) !important;
  color: white !important;
}
.bi-eye,
.bi-x-circle,
.bi-pencil,
.bi-trash {
  cursor: pointer;
}
.pre {
  padding: 0 0 15em 0;
}
.badge {
  color: white !important;
}
.table {
  border: 1px solid black;
}
td {
  border-right: 2px solid black;
}
.bg-info {
  background-color: rgb(98, 91, 83) !important;
}
.confirmation_modifie,
.conteneur-is-delete {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.cont .form-group {
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.222);
  border-radius: 8px;
  background-color: black;
  color: white !important;
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_detail {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 12;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.contenue-is-delete {
  width: auto;
  padding: 1em;
  height: auto;
  background: #ffffff !important;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}

.conteneur_modify .content,
.conteneur_detail .content {
  width: 80%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #d9a509 !important;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.close_modal {
  position: absolute;
  top: 1em;
  right: 1em;
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  z-index: 15;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
}
.submit-pages {
  padding: 1em 3em;
}
.section span,
p span {
  font-weight: bold;
  text-decoration: underline;
}
strong {
  font-size: 1em !important;
  color: gray;
  text-decoration: underline;
  padding: 0 0 0.5em 0;
  font-weight: bold;
  text-transform: uppercase;
}
.text-dark {
  color: black !important;
}

.lien {
  text-decoration: none;
  font-size: 1.3em;

  color: rgb(218, 216, 216);
}

.listes {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.085);
  background-color: white;
  padding: 1em 0;
  border-radius: 10px;
}
.lien:hover {
  color: rgb(255, 187, 0);
}
.color,
.colorHistorique,
.colorNew {
  color: rgb(0, 0, 0) !important;
  border-radius: 5px;
  padding: 0.5em;
  font-weight: bold;
  background-color: rgb(255, 187, 0);
}
</style>
../../utils
