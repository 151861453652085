<script>
import InfoFacturesLocataires from "./InfoFacturesLocataires.vue"
export default {
    name:"InfoLocataires",
    props:{
    infoLocataire:{type:Object},
    NumeroLocataire:{type:Number}
    },
    components:{
    InfoFacturesLocataires
  },
    data(){
        return{

        }
    },
    methods:{
        handleFactures(factures){
            this.$store.dispatch("handleFacturesLocataire",{
                data:factures,
                Modal:true
            })
        }
    }
}
</script>
<template>
 <section class="conteneur_card">
    <InfoFacturesLocataires />
    <h5 class="text-light text-center">Locataire n<sup>o</sup>{{NumeroLocataire}}</h5>
    <strong>Nom & prénoms</strong>
    <h6 class="py-2 m-0">{{ infoLocataire.client.firstname }} {{" "}}{{infoLocataire.client.lastname}}</h6>
    <strong>Email</strong>
    <h6 class="py-2 m-0">{{ infoLocataire.client.email }}</h6>
    <strong>Téléphone</strong>
    <h6 class="py-2 m-0">{{ infoLocataire.client.phone }}</h6>
    <strong>Date d'entrée</strong>
    <h6 class="py-2 m-0">{{ new Date(infoLocataire.date_entree).toLocaleDateString("fr") }}</h6>
    <strong>Date de sortie</strong>
    <h6 class="py-2 m-0">{{ new Date(infoLocataire.date_sortie).toLocaleDateString("fr")}}</h6>
    <div class="my-3">
        <button class="btn bg-dark text-light"
        @click="handleFactures(infoLocataire.factures)"
        >Voir les factures</button>
    </div>
 </section>
</template>
<style scoped>

strong {
    font-weight: 900;
    font-size: 0.9em !important;
    color: gray;
    text-transform: uppercase;
    padding: 0 0 0.5em 0;
    text-decoration: underline;
}
.conteneur_card{
    padding:1em;
    border:1px solid white;
    color:black;
    background: rgb(126, 126, 126) !important;
}
</style>