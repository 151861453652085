<script>

import Swal from "sweetalert2";
import LienConnected from '../../class_help_me'
export default {
  name: "SaveContrat",
  data() {
    return {
      libelleCommodite: "",
      proprietairePropriete: "",
      propriety_id:"",
      ListPropriete:[],
      client_id:null,
      ListClient:[],
      documents:[],
      date_entree:"",
      date_sortie:"",
      nombre_mois_caution:"",
      nombre_mois_avance:"",
      ListProprietaire: [],
      preloader:false,
    };
  },
  methods: {
    handleFile(event){
     console.log(event)
     const files = event.target.files;
     this.documents = Array.from(files)
      console.log("this.documents",this.documents)
    },
    async getList() {
      const listPropriete = [];
      const ListClient = [];
    //   const operations = [];
    //   const listCommune = [];
    //   const listCommodite = [];
      const ListProprietaire = [];
      this.ListPropriete = await this.helpMeForRenderAllList("MaisonLocationIndispo",
      listPropriete);
      this.ListClient = await this.helpMeForRenderAllList("users",
      ListClient);
      console.log("this.ListClient",this.ListClient)

      this.ListProprietaire = await this.helpMeForRenderAllList(
        "proprietaires",
        ListProprietaire
      );
    //   this.listTypeOperation = await this.helpMeForRenderAllList("types", operations);
    //   //console.log("this.listTypeOperation", this.listTypeOperation);
    //   this.listCommodite = await this.helpMeForRenderAllList("comodites", listCommodite);
    //   this.listCommune = await this.helpMeForRenderAllList("municipalite", listCommune);
    //   this.listVille = await this.helpMeForRenderAllList("cities", listVille);

      // console.log("this.listCommune",this.listCommune);
    },
    async enregistrerContrat() {
      
      try{
        this.preloader = true;
        let dataContrat = {
        propriety_id:this.propriety_id.id,
        proprietaire_id:this.proprietairePropriete.id,
      client_id:this.client_id.id,
      documents:this.documents,
      date_entree:this.date_entree,
      date_sortie:this.date_sortie,
      nombre_mois_caution:this.nombre_mois_caution,
      nombre_mois_avance:this.nombre_mois_avance,
      };
      console.log("dataContrat",dataContrat)
      const contrat = new LienConnected('contracts',this.$store.state.token)
     const createCommodite = await contrat.sendData(dataContrat)
     const response = createCommodite
     console.log("response55",response)
     if(response.data.status === true){
               Swal.fire({
              icon: "success",
              title: "Contrat enregistré.",
              showConfirmButton: false,
              timer: 1500,
            });
           
            this.preloader = false
            // setTimeout(()=>{
            //   this.$router.push({name:"showContrat"})
            // },1500)
            
     }
     if(response.data.status === false){
        Swal.fire({
              icon: "info",
              title: response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
            this.preloader = false
     }
   

      }catch(error){
        Swal.fire({
              icon: "info",
              title: error.response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
        this.preloader = false
      }
     
    },
    async helpMeForRenderAllList(route, tableauList) {
      const dataObject = new LienConnected(route, this.$store.state.token);
      let allList = await dataObject.getAllData();
      tableauList = allList.data.data;
      return tableauList;
    },
   
  },
  mounted() {
    this.getList();
  },
};
</script>
<template>
  <div class="col-lg-9 col-md-12 position-relative">
    <div class="dashboard-wraper">
      <div class="row text-start">
        <!-- Submit Form -->
        <div class="col-lg-12 col-md-12">
          <form @submit.prevent="enregistrerContrat">
            <div class="submit-pages">
              <!-- Basic Information -->
              <div class="form-submit">
                <h3 class="text-light">Enregistrer un contrat</h3>
                <div class="submit-section">
                  <div class="row">
                    <div
                    class="form-group col-md-6"
                  >
                    <label>Proprietaire <span class="text-danger">*</span></label>
                    <v-select
                      v-model="proprietairePropriete"
                      :searchable="true"
                      :options="ListProprietaire"
                      label="firstname"
                    >
                      <template #option="{ firstname, lastname }">
                        <p>{{ lastname }} {{ firstname }}</p>
                      </template>
                    </v-select>
                  </div>
                  <div
                    class="form-group col-md-6"
                  >
                    <label>Propriété <span class="text-danger">*</span></label>
                    <v-select
                      v-model="propriety_id"
                      :searchable="true"
                      :options="ListPropriete"
                      label="name"
                    >
                      <template #option="{ name }">
                        <p> {{ name }}</p>
                      </template>
                    </v-select>
                  </div>
                  <div
                    class="form-group col-md-6"
                  >
                    <label>Client <span class="text-danger">*</span></label>
                    <v-select
                      v-model="client_id"
                      :searchable="true"
                      :options="ListClient.filter(item=>item.roles.some((item) => item.label === 'Client'))"
                      label="firstname"
                    >
                    <template #option="{ firstname, lastname }">
                        <p>{{ lastname }} {{ firstname }}</p>
                      </template>
                    </v-select>
                  </div>
                  <div
                  class="form-group col-md-6"
                >
                  <label>Date d'entrée <span class="text-danger">*</span></label>
                  <input
                        type="date"
                        :min="new Date().toISOString().slice(0, 10)"
                        class="form-control"
                        v-model="date_entree"
                      />
                </div>
                <div
                  class="form-group col-md-6"
                >
                  <label>Date de sortie</label>
                  <input
                        type="date"
                        :min="date_entree"
                        class="form-control"
                        v-model="date_sortie"
                      />
                </div>
                <div
                class="form-group col-md-6"
              >
                <label>Caution <span class="text-danger">*</span></label>
                <input
                      type="number"
                      :min="1"
                      class="form-control"
                      v-model="nombre_mois_caution"
                    />
              </div>
              <div
              class="form-group col-md-6"
            >
              <label>Avance en mois <span class="text-danger">*</span></label>
              <input
                    type="number"
                    :min="1"
                    class="form-control"
                    v-model="nombre_mois_avance"
                  />
            </div>
            <div
            class="form-group col-md-6"
          >
            <label>Document <span class="text-danger">*</span></label>
            <input
                  type="file"
                  multiple
                   accept="image/*"
                  class="form-control"
                  @change="handleFile"
                />
          </div>

                    <div>
                      <button
                        :disabled="preloader"
                        class="btn bg-dark text-light rounded"
                        type="submit"
                      >
                        Enregistrer
                        <span class="spinner-border spinner-border-sm ms-2" 
                        v-show="preloader"  role="status" aria-hidden="true"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: relative;
  left: 0;
}
.dashboard-wraper {
  height: 100%;
}
.bi-x-circle {
  cursor: pointer;
}

.circuit {
  padding: 0 !important;
}
[type="radio"]:checked + label:after {
  opacity: 0 !important;
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width: 80%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  z-index: 9999;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
}
.submit-pages_view {
  padding: 1em 3em;
}
</style>
