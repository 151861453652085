import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { AccueilDashboard } from "../views/dashboard";
import {
  Bienvenue,
  Profil,
  ReinitialisePasswordView,
  ListeClient,
  listeVisite,
  createPropriete,
  EnregistrerCommune,
  listeCommune,
  ListeVisiteNonEffectue,
  EnregistrerPaiement,
  PageStatistique,
  historiquePaiement,
  saveProgramme,
  // listProgramme,
  ListVille,
  saveVille,
  saveCategorieProduit,
  listCategorieProduit,
  saveType,
  listeType,
  saveRole,
  listRole,
  ListeCommodite,
  saveCommodite,
  savePersonnel,
  listPersonnel,
  listeReservation,
  reservationConfirme,
  saveProduit,
  listTerrain,
  Statistique,
  accueil,
  lisResidence,
  listAppartement,
  listeAnnulee,
  VisiteNonEffectuee,
  visiteAnnulee,
  occupeHome,
  locationHome,
  listeRequete,
  demandeModification,
  Transaction,
  showPaiement,
  Promotions,
  ListePromotions,
  ListeContrat,
  SaveContrat
} from "../views/dashboard/pages";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/tableau_de_bord",
  },
  {
    path: "/change_password/:email/:token",
    name: "ChangePassword",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/ReinitialisePasswordView.vue"
      ),
  },
  {
    path: "/accueil",
    name: "Dashboard",
    component: AccueilDashboard,
    children: [
      {
        path: "/accueil",
        name: "accueil",
        component: accueil,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: "/tableau_de_bord",
        name: "Bienvenue",
        component: Bienvenue,
        meta:{
          requiresAuth:true
        },
      },
      
   
      {
        path: "/Statistiques",
        name: "PageStatistique",
        component: PageStatistique,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: "/appartement-occupe",
        name: "occupeHome",
        component: occupeHome,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: "/requete-modification",
        name: "demandeModification",
        component: demandeModification,
        meta:{
          requiresAuth:true
        },
      },
      
      {
        path: "/appartement_a_louer",
        name: "locationHome",
        component: locationHome,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: "/recherches",
        name: "listeRequete",
        component: listeRequete,
        meta:{
          requiresAuth:true
        },
      },
      
      
      {
        path: "/visite_annulee",
        name: "visiteAnnulee",
        component:visiteAnnulee,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: "/visite_non_effectuee",
        name: "VisiteNonEffectuee",
        component: VisiteNonEffectuee,
        meta:{
          requiresAuth:true
        },
      },

      {
        path: "/terrain",
        name: "listTerrain",
        component: listTerrain,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: "/reservation_annulee",
        name: "listeAnnulee",
        component:listeAnnulee ,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: "/transactions",
        name: "transactions",
        component:Transaction,
        meta:{
          requiresAuth:true
        },
        
      },

      {
        path: "/Programme",
        name: "saveProgramme",
        component: saveProgramme,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: "/Statistique",
        name: "statistique",
        component: Statistique,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: "/ville",
        name: "ListVille",
        component: ListVille,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: "/type-logement",
        name: "saveType",
        component: saveType,
        meta:{
          requiresAuth:true
        },
      },

      {
        path: "/list-type-logement",
        name: "listeType",
        component: listeType,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: "/role",
        name: "saveRole",
        component: saveRole,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: "/propriete",
        name: "createPropriete",
        component:createPropriete,
        meta:{
          requiresAuth:true
        },
      },
     
      {
        path: "/residence",
        name: "listResidence",
        component: lisResidence,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: "/appartement",
        name: "listAppartement",
        component: listAppartement,
        meta:{
          requiresAuth:true
        },
      },
      
      
      {
        path: "/list-role",
        name: "listRole",
        component: listRole,
        meta:{
          requiresAuth:true
        },
      },

      {
        path: "/Liste-Commodite",
        name: "ListeCommodite",
        component: ListeCommodite,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: "/Commodite",
        name: "saveCommodite",
        component: saveCommodite,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: "/utilisateur",
        name: "savePersonnel",
        component: savePersonnel,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: "/liste-utilisateur",
        name: "listPersonnel",
        component: listPersonnel,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: "/reservation",
        name: "listeReservation",
        component: listeReservation,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: "/reservation_confirmee",
        name: "reservationConfirmee",
        component:reservationConfirme,
        meta:{
          requiresAuth:true
        },
      },
   
      {
        path: "/produit",
        name: "saveProduit",
        component: saveProduit,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: "/promotions",
        name: "sendPromotions",
        component: Promotions,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: "/liste-promotions",
        name: "listePromotions",
        component: ListePromotions,
        meta:{
          requiresAuth:true
        },
      },
      
      {
        path: "/show-paiement",
        name: "showPaiement",
        component:showPaiement,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: "/contrats",
        name: "showContrat",
        component:ListeContrat,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: "/enregistrement_contrats",
        name: "saveContrat",
        component:SaveContrat,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: "/ville",
        name: "saveVille",
        component: saveVille,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: "/Categorie",
        name: "saveCategorieProduit",
        component: saveCategorieProduit,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: "/list-categorie",
        name: "listCategorieProduit",
        component: listCategorieProduit,
        meta:{
          requiresAuth:true
        },
      },

      {
        path: "/historique-paiement",
        name: "historiquePaiement",
        component: historiquePaiement,
        meta:{
          requiresAuth:true
        },
      },

      {
        path: "/paiement",
        name: "EnregistrePaiement",
        component: EnregistrerPaiement,
        meta:{
          requiresAuth:true
        },
      },

      {
        path: "/visite",
        name: "ListeVisiteNonEffectue",
        component: ListeVisiteNonEffectue,
        meta:{
          requiresAuth:true
        },
      },

      {
        path: "/liste-visite",
        name: "listeVisite",
        component: listeVisite,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: "/commune",
        name: "saveCommune",
        component: EnregistrerCommune,
        meta:{
          requiresAuth:true
        },
      },

      {
        path: "/liste-client",
        name: "listeClient",
        component: ListeClient,
        meta:{
          requiresAuth:true
        },
      },

      {
        path: "/liste_commune",
        name: "listeCommune",
        component: listeCommune,
        meta:{
          requiresAuth:true
        },
      },

      {
        path: "/mon_profil",
        name: "Profil",
        component: Profil,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: "/reinitialiser_mon_mot_de_passe",
        name: "ReinitialisePassword",
        component: ReinitialisePasswordView,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});
router.beforeEach((to, _from, next) => {
  if (to.meta.requiresAuth) {
    const session = localStorage.getItem("token");
    if (session === null) {
      next({
        path: "/",
      });
    }
    if (session !== null) {
      next();
    }
  } else {
    next();
  }
});

export default router;
