<script>
// import {utils} from '../../../../utils'
import $ from "jquery";
import Swal from "sweetalert2";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import LienConnected from "../../class_help_me";
export default {
  name: "liste-Maison",
  data() {
    return {
      // myUtils:utils,
      showWindowDelete: false,
      imageEssay:"",
      moneyFormat: new Intl.NumberFormat("de-DE"),
      showDetailResidenceForModify: false,
      preload: false,
      visibleResidence: "",
      preloader: false,
      showDetailClient: false,
      showContrat: false,
      listMaison: [],
      windowConfirmeSold:false,
      listMaisonEnattente: [],
      listMaisonAchetees: [],
      dataObject: "",
      detailProduct: null,
      idProduct: "",
      IdCategorie: "",
      preloaderSpinner: false,
      photoResidence: [],
      lienUrl: new LienConnected().baseUrl,
      tabulation: "disponible",
      windowForDeleteReal:false,
    spinnerLocation:false,
      railStyle: ({
        focused,
        checked
      }) => {
        const style = {};
        if (checked) {
          style.background = "#d0d0d0";
          if (focused) {
            style.boxShadow = "0 0 0 2px #d0305040";
          }
        } else {
          style.background = "#2080f0";
          if (focused) {
            style.boxShadow = "0 0 0 2px #2080f040";
          }
        }
        return style;
      },

      commoditeDetail:[],
      preloaderModify:false,
      idPropriete: "",
      screenForModifyHome: false,
      photoDetail:[],
      idHouse: "",
      Listcategorie:[],
      type_idDetail: "",
      HomeDetail: "",
      typeDetail: "",
      costDetail: "",
      documentDetail: "",
      roomDetail: "",
      cautionDetail: "",
      descriptionDetail: "",
      listTypeOperation:[],
      listCommune:[],
      listCommodite:[],
      lotDetail: "",
      areaDetail: "",
      ilotDetail: "",
      adressDetail: "",
      person_maxiDetail: "",

      municipalityDetail: "",

      category_idDetail: "",
      registered_byDetail: "",
      spinnerDeletePicture:false,

      nombreDePhotoRestante: "",
      newArrayImage: [],
      toggleWindowForDelete:false,
      spinnerDelete:false,
      categorieChosen:"",
      imagePropriete:[],

    };
  },
  methods: {
    async modifierStatutHome(status, idHome) {
      //console.log("idHome", idHome);
      //console.log("status", status);
      try {
        let statutResidence = status == "Disponible" ? "Disponible" : status == "Vendu" ? "Vendu" : "Indisponible"; 
        let data = {
          status: statutResidence,
        };
        //console.log("STATUS", data);
        this.helpMeGetList("updateStatus");
        const allList = await this.dataObject.modifyDataWithPost(data, idHome);
        //console.log("allList", allList);
        if (allList.data.status === true) {
          //console.log(allList);
          this.getListMaison();
          this.windowConfirmeSold = false
        }

        //console.log("NEW LIST", this.listResidenceDisponible);
      } catch (error) {
        this.getListMaison();
        console.log(error);
      }
    },
    helpMeGetList(route) {
      this.dataObject = new LienConnected(route, this.$store.state.token);
    },
    async getAllListMaison(route,code=1) {
      try {
        this.preloader = true;
        this.helpMeGetList(route);
        const allList = await this.dataObject.getAllData();
        if (allList.data.status === true) {
          let list = [];
          list = allList.data.data;

          if (code == 1) {
            list.forEach((item) => {
              item.photoPropriete = this.putImageInArray(item.photos);
              item.IdHome = item.id;
            });
          }
          this.preloader = false;
          return list;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getListMaison() {
      this.listMaison = await this.getAllListMaison(
        "MaisonVendre"
      );
      this.listMaisonEnattente = await this.getAllListMaison(
        "MaisonsIndisponible"
      );
      this.listMaisonAchetees = await this.getAllListMaison(
        "getpayMaisons"
      );

      this.Listcategorie = await this.getAllListMaison("categories", 0);
      this.listTypeOperation = await this.getAllListMaison("types", 0);

      this.listCommodite = await this.getAllListMaison("comodites", 0);
      this.listCommune = await this.getAllListMaison("municipalite", 0);
      this.listVille = await this.getAllListMaison("cities", 0);
      //console.log("this.listMaison",this.listMaison)
      //console.log("this.listMaisonEnattente",this.listMaisonEnattente)
      //console.log("this.listMaisonAchetees",this.listMaisonAchetees)
      setTimeout(function () {
        let printCounter = 0;
        $("#MyTableData,#MyTableData2,#MyTableData1").DataTable({
          pagingType: "full_numbers",
          pageLength: 10,
          processing: true,
          dom: "Bfrtip",
          buttons: [
            {
              extend: "copy",
              text: "Copier les données",
              messageTop: function () {
                printCounter++;

                if (printCounter === 1) {
                  Swal.fire({
                    icon: "success",
                    title:
                      "Les données ont été copiées dans le presse-papiers.",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                } else {
                  Swal.fire({
                    icon: "info",
                    title:
                      "Les données ont déjà été copiées dans le presse-papiers.",
                    showConfirmButton: true,
                  });
                }
              },
            },
            {
              extend: "csv",
              text: "Exporter en fichier excel",
              fieldSeparator: ";",
              fieldBoundary: '"',
              bom: true,
              exportOptions: {
                columns: [0],
              },
            },
            {
              extend: "print",
              text: "Imprimer les données",
            },
          ],
          order: [],
          language: {
            décimal: "",
            emptyTable: "Aucune donnée disponible dans le tableau",
            infoEmpty: "Showing 0 to 0 of 0 entries",
            info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
            infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
            infoPostFix: "",
            thousands: ",",
            lengthMenu: "Afficher les entrées du _MENU_",
            loadingRecords: "Loading...",
            processing: "Processing...",
            search: "Chercher :",
            stateSave: true,
            zeroRecords: "Aucun enregistrement correspondant trouvé",
            paginate: {
              first: "Premier",
              last: "Dernier",
              next: "Suivant",
              previous: "Précédent",
            },
            aria: {
              sortAscending: ": activate to sort column ascending",
              sortDescending: ": activate to sort column descending",
            },
          },
        });
      }, 10);
    },
  
    putImageInArray(arrayPicture) {
      let compteur = 0;
      let images = [];
      while (compteur < arrayPicture.length) {
        images.push(
          this.lienUrl.replace("/api/", "/") +
            "public/public/image/" +
            arrayPicture[compteur].image
        );
        compteur++;
      }
      return images;
    },
    bouclerInList(liste,id){
      liste.forEach((item) => {
        if (item.id == id) {
          item.flag = 0
          this.detailProduct = item;
          // console.log(this.detailProduct)
        }
      });
    },

    async isItemFlag(liste,id){
      try{
        const dataObject = new LienConnected("updateFlagP/"+id,this.$store.state.token)
      const response = await dataObject.sendData(null);
      // console.log("response",response)
      if(response.data.status){
        this.$store.dispatch("seeAlarmMaisonSell")
      this.bouclerInList(liste,id)
      }
      }catch(error){
        console.log(error)
      }
    },
   
    async viewDetailsHome(id, list) {
      this.idProduct = id;
      list.forEach((item) => {
        if (item.id == id) {
          this.detailProduct = item
          // console.log("this.detailProduct",this.detailProduct)
          item.photoPropriete = this.putImageInArray(item.photos);
        }
      });
     
      if(this.detailProduct.flag == 1){
        this.isItemFlag(list,id)
      }
     
      this.showDetailResidenceForModify = !this.showDetailResidenceForModify;
    },
    closeWindowDetailResidenceForModify() {
      this.showDetailResidenceForModify = !this.showDetailResidenceForModify;
    },
    sellHome(id){
      this.windowConfirmeSold = !this.windowConfirmeSold
      this.idHome = id
    },
    categorieToogle(value) {
      this.Listcategorie.forEach((item) => {
        if (item.id == value) this.categorieChosen = item.label;
      });
    },
    modifierHome(idHome,resetDataAfterClose=0){
      
     this.listMaison.forEach(item=>{
       if(item.id === idHome){
         this.detailHome = item
         this.idHouse = item.id
     this.categorieToogle(item.category_id)
     //console.log("item.commodites",item.comodites)
      this.type_idDetail = item.type_id
      this.commoditeDetail = item.comodites
   this.HomeDetail = item.name
   this.typeDetail = item.type
   this.costDetail = item.cost
   this.documentDetail = item.document 
   this.roomDetail = item.room
   this.cautionDetail = item.caution  
   this.descriptionDetail = item.description
   this.lotDetail = item.lot
  this.areaDetail = item.area 
   this.ilotDetail = item.ilot
  this.adressDetail = item.adress
   this.person_maxiDetail = item.person_maxi
  this.municipalityDetail = item.municipality
   this.category_idDetail = item.category_id
   this.registered_byDetail = item.registered_by 
   this.photoDetail = this.putImageInArray(item.photos)
  
   this.nombreDePhotoRestante = 5-this.photoDetail.length
       }

     })
     //console.log("this.detailHome",this.detailHome)
     //console.log("this.tableauTemporaire",this.tableauTemporaire)
     this.screenForModifyHome = resetDataAfterClose == 0 ? true:false;

     //console.log(this.screenForModifyHome)
   },
    wouldDeleteHome(idHome){
      this.idHome = idHome
     this.windowForDeleteReal = !this.windowForDeleteReal
    },
    async deleteHome(){ 
      this.spinnerDelete = true
     this.helpMeGetList('proprietes')
     const allResponse = await this.dataObject.deleteData(this.idHome);
     //console.log("allResponse",allResponse)
     try{
     
      if(allResponse.data.status === true || allResponse.data.status == 'true'){
        const deteleItem = this.listMaison.findIndex(item=>item.id === this.idHome)
       this.listMaison.splice(deteleItem,1)
        Swal.fire({
         icon: "success", 
         title: "Propriété supprimée.",
         showConfirmButton: true,
       });
       this.spinnerDelete = false
       this.windowForDeleteReal = !this.windowForDeleteReal
       this.idHome = "";
      }
     }catch(error){
      //console.log(error)
      this.spinnerDelete = false
     }
    },
    closeWindow() {
      //console.log("this.idHouse",this.idHouse)
      this.modifierHome(this.idHouse, 1);
    },
    iterateInArrImages(tableau) {
      let compteur = 0;
      let filesArray = [];
      while (compteur < tableau.length) {
        filesArray.push(tableau[compteur].file);
        compteur++;
      }
      return filesArray;
    },
    handleMedias(file) { 
      //console.log(file);
      this.imagePropriete = this.iterateInArrImages(file.fileList);
      //console.log("Photo", this.imagePropriete);
    },
    WantDoDeletePicture(element) {
     
     const substringToRemove = "http://backend-dexter.invest-ci.com/public/public/image/";

     const modifiedURL = element.replace(substringToRemove, "");
     this.detailHome.photos.forEach((item) => {
       if (item.image == modifiedURL) {
       
         this.idPictureForDelete = item.id;
         //console.log("this.idPictureForDelete",this.idPictureForDelete);
       }
     });
     this.toggleWindowForDelete = !this.toggleWindowForDelete;
   },
   async removePicture() {
     this.spinnerDeletePicture = true;
     this.helpMeGetList("photos");
     //console.log("this.idPictureForDelete",this.idPictureForDelete)
     const responseForDeletePicture = await this.dataObject.deleteData(
       this.idPictureForDelete
     );
     //console.log("responseForDeletePicture",responseForDeletePicture)
     try {
      
       if (responseForDeletePicture.data.status === true) {
         Swal.fire({
           icon: "success",
           title: "Image supprimée.",
           showConfirmButton: true,
         });
        
           let image = null;
           //console.log("this.detailHome.photos",this.detailHome.photos)
         this.photoDetail.forEach(item=>{
           const substringToRemove = "http://backend-dexter.invest-ci.com/public/public/image/";
           
          this.detailHome.photos.forEach(element=>{
           if(item.replace(substringToRemove, "") == element.image){
             image = item.replace(substringToRemove, "")
           }
          })
         })
         this.imageEssay = image
        //  console.log("IMAGE AT DELETE",image)
         //console.log("this.photoDetail",this.photoDetail)

    
          
        const deletePicture = this.detailHome.photos.findIndex((picture) => picture.id === this.idPictureForDelete)
        
          this.detailHome.photos.splice(deletePicture,1)
          this.photoDetail = this.putImageInArray(this.detailHome.photos)
          this.nombreDePhotoRestante = 5 - this.photoDetail.length;
         //console.log("this.photoDetail",this.detailHome.photos)

         this.spinnerDeletePicture = false;
         this.toggleWindowForDelete = !this.toggleWindowForDelete;
         this.spinnerDeletePicture = false;
       }
     } catch (error) {
       this.spinnerDeletePicture = false;
       console.log(error);
     }
   },
   iterateInArrCommodite(tableau) {
      let compteur = 0;
      let commoditeArray = [];
      while (compteur < tableau.length) {
        let idLibelle = tableau[compteur].label.id
          ? tableau[compteur].label.id
          : tableau[compteur].pivot.comodity_id;
        commoditeArray.push({
          comodity_id: idLibelle,
          number: tableau[compteur].pivot.number,
        });
        compteur++;
      }
      return commoditeArray;
    },
   async sendModificationHome() {
      this.preloaderModify = true;
      const tableauCommodite = this.iterateInArrCommodite(
        this.commoditeDetail
      );
   

      let data = new FormData();

      tableauCommodite.forEach((commodite) => {
        data.append("comodites[]", JSON.stringify(commodite));
      });

    
      data.append("type_id", this.type_idDetail ? this.type_idDetail:"");
      data.append("name", this.HomeDetail ? this.HomeDetail:"");
      data.append("type", this.typeDetail ? this.typeDetail:"");
      data.append("cost", this.costDetail ? this.costDetail:"");
      data.append("document", this.documentDetail ? this.documentDetail:"");
      data.append("room",this.roomDetail ? this.roomDetail:"" );
      data.append("caution",this.cautionDetail ? this.cautionDetail:"");

      data.append("description", this.descriptionDetail ? this.descriptionDetail : "");
      data.append("lot", this.lotDetail ? this.lotDetail: "");
      data.append("area", this.areaDetail ? this.areaDetail:"");
      data.append("ilot", this.ilotDetail ? this.ilotDetail:"");
      data.append("adress", this.adressDetail ? this.adressDetail:"");
      data.append("person_maxi", this.person_maxiDetail ? this.person_maxiDetail:"");
      data.append("municipality_id", this.municipalityDetail.id ? this.municipalityDetail.id:"");
      data.append("category_id", this.category_idDetail ? this.category_idDetail:"");
      data.append("registered_by", this.registered_byDetail ? this.registered_byDetail : "");

 

     

      this.imagePropriete.forEach((item) => {
        data.append("image[]", item);
      });
      // data.append("propriety_id", this.idPropriete);

      try {
        this.helpMeGetList("updatePropriete");
        const response = await this.dataObject.modifyDataWithPost(
          data,
          this.idHouse,"Propriété modifié"
        );

     

      
        if (
          response.data.status === true
        ) {
          this.screenForModifyHome = !this.screenForModifyHome; 
          
         
          //console.log("RESPONSE BIEN",response);
          this.detailHome.type_id = response.data.data.type_id;
          this.detailHome.name = response.data.data.name;
          this.detailHome.type = response.data.data.type;
          this.detailHome.cost = response.data.data.cost;
          this.detailHome.document = response.data.data.document;
          this.detailHome.room = response.data.data.room;
          this.detailHome.caution = response.data.data.caution;
          this.detailHome.description = response.data.data.description;
          this.detailHome.lot = response.data.data.lot;
          this.detailHome.area = response.data.data.area;
          this.detailHome.ilot = response.data.data.ilot;
          this.detailHome.adress = response.data.data.adress;
          this.detailHome.person_maxi = response.data.data.person_maxi;
         
          this.detailHome.category_id = response.data.data.category_id;
          this.detailHome.registered_by = response.data.data.registered_by;
          this.detailHome.photos = response.data.data.photos
          this.detailHome.photoPropriete = this.putImageInArray(
            response.data.data.photos
          );
          this.photoDetail = this.detailHome.photoPropriete
         
          //console.log("SAVEthis.photoDetail",this.photoDetail)
          this.preloaderModify = false;
          
        }
        this.preloaderModify = false;
      } catch (error) {
        this.preloaderModify = false;
      }
    },
    addField: function () {
      this.commoditeDetail.push({
        label: "",
        pivot: {
          number: "",
        },
      });
      //console.log(this.commoditeDetail);
    },
    deleteField: function (element) {
      const DeleteField = this.commoditeDetail.findIndex(
        (item) => item === element
      );
      this.commoditeDetail.splice(DeleteField, 1);
    },
    verifyIfRoleInclus(role){
      const {roles} = JSON.parse(localStorage.getItem("user"))
        return roles.some(item=>item.label === role)
       }
  },
  mounted() {
    this.getListMaison();
  },
};
</script>
<template>

  <div class="confirmation_modifie" v-if="toggleWindowForDelete">
    <div class="card">
      <h6 class="text-center text-secondary">
        Voulez-vous vraiment supprimer l'image ?
      </h6>

      <div class="d-flex justify-content-center align-items-center">
        <div>
          <button
            class="btn btn-all mx-2 fw-bold bg-dark"
            @click.prevent="removePicture"
          >
            Confirmer
            <span
              class="spinner-border spinner-border-sm ms-2"
              v-if="spinnerDeletePicture"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
          <button
            class="btn bg-danger mx-2 text-white fw-bold"
            @click.prevent="toggleWindowForDelete = !toggleWindowForDelete"
          >
            Annuler
          </button>
        </div>
      </div>
    </div>
  </div>


  <div class="confirmation_modifie" v-show="windowConfirmeSold">
    <div class="card">
      <h6 class="text-center text-secondary ">
        Veuillez-confirmer ?</h6>
      
      <div class="d-flex justify-content-center align-items-center">
       
        <div>
          <button
          class="btn btn-all  mx-2 bg-dark" 
          @click.prevent="modifierStatutHome('Vendu',this.idHome)"
        >
          Confirmer
        </button>
        <button class="btn bg-danger mx-2 text-white" @click.prevent="windowConfirmeSold = !windowConfirmeSold">
          Annuler
        </button>
        </div>
      </div>
    </div>
  </div>

  <div class="confirmation_modifie" v-show="windowForDeleteReal">
    <div class="card">
      <h6 class="text-center text-secondary ">
        Voulez-vous vraiment supprimer la propriété ?</h6>
      
      <div class="d-flex justify-content-center align-items-center">
       
        <div>
          <button
          class="btn btn-all  mx-2  fw-bold bg-dark" 
          @click.prevent="deleteHome()"
        >
          Confirmer
          <span class="spinner-border spinner-border-sm ms-2"
           v-show="spinnerDelete"  role="status" aria-hidden="true"></span>
        </button>
        <button class="btn bg-danger mx-2 text-white fw-bold"
         @click.prevent="windowForDeleteReal = !windowForDeleteReal">
          Annuler
        </button>
        </div>
      </div>
    </div>
  </div>


  <div class="col-lg-9 col-md-12">

    <div class="dashboard-wraper">

      <div class="conteneur_modify" v-if="screenForModifyHome">
        <div class="content position-relative">
          <div
            v-if="detailHome"
            class="ecriteau text-start d-flex justify-content-between align-items-center"
          >
            <h4>Modifier les informations</h4>
            <i class="bi bi-x-circle h1" @click.prevent="closeWindow"></i>
          </div>
          <div class="text-start px-3">
            <div class="form-submit">
              <div class="submit-section">
                <div class="row my-3">
                  <div class="form-group col-md-6">
                    <label class="text-dark">
                      Catégorie de la propriéte
                      <span class="text-danger">*</span></label
                    >

                    <select
                      v-model="category_idDetail"
                      class="form-control"
                      disabled
                    >
                      <option disabled value="">
                        Sélectionne une catégorie
                      </option>
                      <option
                        v-for="(categorie, index) in Listcategorie"
                        :key="index"
                        :value="categorie.id"
                        :id="categorie.label"
                      >
                        {{ categorie.label }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="text-dark"
                      >Libellé <span class="text-danger">*</span></label
                    >
                    <input
                    disabled
                      type="text"
                      class="form-control"
                      v-model="HomeDetail"
                      required
                    />
                  </div>

                  <div
                    class="form-group col-md-6"
                    v-if="categorieChosen == 'Terrain'"
                  >
                    <label class="text-dark"
                      >Ilot <span class="text-danger">*</span></label
                    >

                    <input
                    disabled
                      type="text"
                      class="form-control"
                      v-model="ilotDetail"
                      required
                    />
                  </div>
                  <div
                    class="form-group col-md-6"
                    v-if="categorieChosen == 'Terrain'"
                  >
                    <label class="text-dark"
                      >Numéro de lot <span class="text-danger">*</span></label
                    >
                    <input
                      disabled
                      type="text"
                      class="form-control"
                      v-model="lotDetail"
                      required
                    />
                  </div>
                  <div class="form-group col-md-6"  v-if="categorieChosen != 'Terrain'">
                    <label class="text-dark"
                      >Type de Propriéte
                      <span class="text-danger">*</span></label
                    >

                    <input
                    disabled
                      type="text"
                      class="form-control"
                      v-model="typeDetail"
                      required
                    />
                  </div>

                  <div
                    class="form-group col-md-6"
                    v-if="
                      categorieChosen == 'Résidence' ||
                      categorieChosen == 'Maison'
                    "
                  >
                    <label class="text-dark"
                      >Type de l'operation
                      <span class="text-danger">*</span></label
                    >
                    <select  disabled v-model="type_idDetail" class="form-control">
                      <option disabled value="">Veuillez sélectionner</option>
                      <option
                        v-for="item in listTypeOperation"
                        :key="item"
                        :value="item.id"
                      >
                        {{ item.label }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="text-dark"
                      >Prix <span class="text-danger">*</span></label
                    >
                    <input
                      type="number"
                      min="1"
                      class="form-control"
                      v-model="costDetail"
                      required
                    />
                  </div>
                  <!-- <div
                    class="form-group col-md-6"
                    v-if="categorieChosen == 'Maison'"
                  >
                    <label class="text-dark">Caution (Nombre de mois)</label>

                    <input
                      type="number"
                      min="1"
                      class="form-control"
                      v-model="cautionDetail"
                    />
                  </div> -->

                  <div
                    class="form-group col-md-6"
                    v-if="
                      categorieChosen == 'Maison' ||
                      categorieChosen == 'Résidence'
                    "
                  >
                    <label class="text-dark"
                      >Nombre de pièces
                      <span class="text-danger">*</span></label
                    >

                    <input
                    
                      type="number"
                      min="1"
                      class="form-control"
                      v-model="roomDetail"
                      required
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label class="text-dark"
                      >Superficie (m<sup>2</sup>)<span class="text-danger"
                        >*</span
                      ></label
                    >

                    <input
                    disabled
                      type="number"
                      min="1"
                      class="form-control"
                      v-model="areaDetail"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="text-dark"
                      >Commune <span class="text-danger">*</span></label
                    >

                    <v-select
                    :disabled="true"
                      placeholder="Sélectionner une commune"
                      v-model="municipalityDetail"
                      :searchable="true"
                      :options="listCommune"
                      label="name"
                    >
                      <template>
                        <p>{{ label }}</p>
                      </template>
                    </v-select>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="text-dark">Situé précisément</label>

                    <input
                    disabled
                      type="text"
                      class="form-control"
                      v-model="adressDetail"
                      required
                    />
                  </div>
                  <div
                    class="form-group col-md-6"
                    v-if="categorieChosen == 'Résidence'"
                  >
                    <label class="text-dark"
                      >Nombre de personne autorisées
                      <span class="text-danger">*</span></label
                    >

                    <input
                      type="number"
                      class="form-control"
                      min="1"
                      v-model="person_maxiDetail"
                      required
                    />
                  </div>
                  <div class="form-group col-md-12">
                    <label class="text-dark">Description</label>
                    <textarea
                      class="form-control"
                      required
                      v-model="descriptionDetail"
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                    >
                    </textarea>
                  </div>

                  <div
                    class="form-group col-md-12"
                    v-if="
                      categorieChosen == 'Maison' ||
                      categorieChosen == 'Résidence'
                    "
                  >
                    <div
                      v-for="field in commoditeDetail"
                      :key="field"
                      class="position-relative row"
                    >
                      <span
                        v-if="field"
                        class="delete badge bg-danger fw-bold"
                        @click="deleteField(field)"
                        ><i class="bi bi-trash-fill"></i
                      ></span>
                      <div class="col-lg-6">
                        <label :for="field" class="text-dark">Commodité</label>
                        <v-select
                          v-model="field.label"
                          :searchable="true"
                          placeholder="Sélectionner une commodité"
                          :options="listCommodite"
                          label="label"
                        >
                          <template>
                            <p>{{ label }}</p>
                          </template>
                        </v-select>
                      </div>
                      <div class="col-lg-6">
                        <label :for="field" class="text-dark"
                          >Nombre de la commodité</label
                        >
                        <input
                          :id="field"
                          type="number"
                          min="1"
                          class="form-control"
                          v-model="field.pivot.number"
                        />
                      </div>
                    </div>
                    <div class="text-end">
                      <button
                        @click.prevent="addField"
                        class="btn w-25 my-3 addField text-light bg-dark"
                      >
                        Ajouter une commodité +
                      </button>
                    </div>
                  </div>
                  <div
                    class="form-group col-md-6"
                    v-if="categorieChosen == 'Terrain'"
                  >
                    <label class="text-dark"
                      >Document <span class="text-danger">*</span></label
                    >
                    <div class="row container">
                      <section class="col-md-12 circuit">
                        <div class="col-md-12 d-flex align-items-center my-2">
                          <input
                            type="radio"
                            id="ACD"
                            name="fav_language"
                            value="ACD"
                            required
                            v-model="documentDetail"
                          />
                          <label class="text-dark" for="ACD">ACD</label>
                        </div>

                        <div class="col-md-12 d-flex align-items-center my-2">
                          <input
                            type="radio"
                            id="Approbation Ministeriel"
                            name="fav_language"
                            value="ACD en cours"
                            required
                            v-model="documentDetail"
                          />
                          <label class="text-dark" for="Approbation Ministeriel"
                            >ACD en cours</label
                          >
                        </div>
                      </section>
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="text-dark"
                      >Appartenance <span class="text-danger">*</span></label
                    >

                    <select
                      v-model="registered_byDetail"
                      class="form-control"
                      disabled
                    >
                      <option disabled value="">Veuillez sélectionner</option>
                      <option value="administrateur">Dexter</option>
                      <option value="fournisseur">Fournisseur</option>
                    </select>
                  </div>

                  <div class="form-group col-md-12 text-start">
                    <label class="text-dark d-block">
                      Galerie <span class="text-danger">*</span></label
                    >
                   
                    <div
                      class="d-flex justify-content-center align-items-center gap-3"
                    >
                      <span
                        class="position-relative"
                        v-for="(item, index) in photoDetail"
                        :key="index"
                      >
                        <i
                          @click.prevent="WantDoDeletePicture(item)"
                          class="bi bi-trash-fill trash-two text-danger position-absolute"
                        ></i>
                        <n-image width="200" height="200" class="img-fluid position-absolute image-a-change" :src="item" />
                      </span>
                    </div>

                    <n-upload
                      @change="handleMedias"
                      multiple
                     
                      :default-file-list="newArrayImage"
                      list-type="image-card"
                    >
                    Clique pour télécharger
                    </n-upload>
                  </div>

                  <div class="text-start mt-3">
                    <button
                      class="btn bg-dark text-light rounded"
                      @click.prevent="sendModificationHome"
                    >
                      Modifier
                      <span
                        class="spinner-border spinner-border-sm ms-2"
                        v-show="preloaderModify"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="conteneur_modify" v-if="showDetailResidenceForModify">
        <div class="content position-relative">
          <div
            class="ecriteau text-start d-flex justify-content-between align-items-center"
          >
            <h4>Détail de la maison</h4>
            <i
              class="bi bi-x-circle h1"
              @click.prevent="closeWindowDetailResidenceForModify"
            ></i>
          </div>

          <div class="text-start px-3 color_container">
            <div class="submit-pages text-start" v-if="detailProduct">
              <div class="row container product cont">
                <div class="form-group col-md-12 col-sm-12">
                  <div>
                    <h5 class="badge bg-info">Info Résidence</h5>
                  </div>
                  <code>{{detailProduct}}</code>
                  <div class="row">
                    <div class="col-md-12 col-sm-12">
                      <strong>Maison</strong>
                      <h6 class="p-0 m-0">{{ detailProduct.name }}</h6>
                      <strong v-if="detailProduct.sale_type">Type de l'opération</strong>
                      <h6 class="p-0 m-0">{{ detailProduct.sale_type }}</h6>
                     
                      <strong>Prix (Fcfa) </strong>
                      <h6 class="p-0 m-0">
                        {{ moneyFormat.format(detailProduct.cost) }}
                      </h6>
                      <strong v-if="detailProduct.caution">Caution (Nbre de mois) </strong>
                      <h6 class="p-0 m-0">
                        {{ detailProduct.caution }}
                      </h6>
                      <strong>Située</strong>
                      <h6 class="p-0 m-0">
                        {{ detailProduct.municipality.city.name }}
                        {{ detailProduct.municipality.name }}
                      </h6>
                      <strong>Situé précisément</strong>
                      <h6 class="p-0 m-0">{{ detailProduct.adress }}</h6>
                      <strong v-if="detailProduct.description"
                        >Description</strong
                      >
                      <h6 class="p-0 m-0">{{ detailProduct.description }}</h6>
                      <strong>Galerie</strong>
                      <MazGallery
                        v-if="detailProduct.photoPropriete"
                        :images="detailProduct.photoPropriete"
                        :height="400"
                      />
                      <h6 v-else class="text-light">Pas de photo</h6>
                    
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-12 col-sm-12">
                  <div>
                    <h5 class="badge bg-info">Info Proprietaire : 
                      <span class="text-dark">
                      {{ detailProduct.registered_by}}
                    </span>
                
                 </h5>
                  </div>

                  <strong>Nom & prénoms</strong>
                  <h6 class="p-0 m-0">
                    {{ detailProduct.user.lastname }}
                    {{ detailProduct.user.firstname }}
                  </h6>
                  <strong>Email </strong>
                  <h6 class="p-0 m-0">{{ detailProduct.user.email }}</h6>
                  <strong>Téléphone </strong>
                  <h6 class="p-0 m-0">{{ detailProduct.user.phone }}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Bookmark Property -->
      <div
        class="form-submit position-relative"
        :class="preloader ? 'pre' : 'null'"
      >
        <div id="preloader" class="position-absolute" v-show="preloader">
          <div class="preloader"><span></span><span></span></div>
        </div>
        <h2 class="text-start text-light">Maisons à vendre</h2>
        <ul class="d-flex justify-content-start align-items-center px-5 listes">
          <li  @click.prevent="tabulation = 'disponible'">
            <a
              href="#"
              :class="{ color: tabulation == 'disponible' }"
              class="lien position-relative"
             
              >Approuvées
              <span v-show="tabulation == 'disponible'" class="badge bg-danger badge-total position-absolute">{{
                listMaison.length
              }}</span>
              </a
            >
          </li>
          <li class="mx-3" @click.prevent="tabulation = 'indisponible'">
            <a
              href="#"
              :class="{ colorNew: tabulation == 'indisponible' }"
              class="lien position-relative"
           
            >
              En attente d'approbation
              <span v-show=" tabulation == 'indisponible'" class="badge bg-danger badge-total position-absolute">{{
                listMaisonEnattente.length
              }}</span>
              </a
            >
          </li>
          <li class="mx-3" @click.prevent="tabulation = 'achetees'">
            <a
              href="#"
              :class="{ colorNew: tabulation == 'achetees' }"
              class="lien position-relative"
              
            >
              Achetées
              <span v-show="tabulation == 'achetees'" class="badge bg-danger badge-total position-absolute">{{
                listMaisonAchetees.length
              }}</span>
              </a
            >
          </li>
        </ul>
      </div>
      <section v-show="tabulation == 'disponible'">
        <div class="col-sm-12 dataTable" v-if="!preloader">
          <table id="MyTableData" class="table text-center table-responsive">
            <thead>
              <tr class="text-center">
                <th class="bg-light text-center">Maison</th>
                <th class="bg-light text-center">Type de propriété</th>
                <th class="bg-light text-center">Image</th>
                <th class="bg-light text-center">Prix(Fcfa)</th>
                <th class="bg-light text-center">Située</th>
                <th class="bg-light text-center">Nbre de pièces</th>

                <th class="bg-light text-center">Propriétaire</th>
                <th class="bg-light text-center" 
                v-if="!this.verifyIfRoleInclus('Commercial')"
                >Désapprouvée</th>
                <th class="bg-light text-center" 
                v-if="!this.verifyIfRoleInclus('Commercial')">Action</th>
                <th class="bg-light text-center">Détail</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(maison, index) in listMaison" :key="index"
              :class="maison.flag == 1 ? 'bg-warning':null"
              >
                <td
                  @click.prevent="viewDetailsHome(maison.id, listMaison)"
                >
                  {{ maison.name }}
                </td>
                <td
                @click.prevent="viewDetailsHome(maison.id, listMaison)"
              >
                {{ maison.type }}
              </td>
                <td>
                  <n-image
                    width="50"
                    :src="
                      this.lienUrl.replace('/api/', '/') +
                      'public/public/image/' +
                      maison.photos[0].image
                    "
                    v-if="maison.photos.length"
                  />
                  <span class="badge bg-danger" v-else>x</span>
                </td>
                <td
                  @click.prevent="viewDetailsHome(maison.id, listMaison)"
                >
                  {{ moneyFormat.format(maison.cost) }}
                </td>
                <td
                  @click.prevent="viewDetailsHome(maison.id, listMaison)"
                >
                  {{ maison.municipality.city.name }},
                  {{ maison.municipality.name }}
                </td>
                <td
                  @click.prevent="viewDetailsHome(maison.id, listMaison)"
                >
                  {{ maison.room }}
                </td>

                <td
                  @click.prevent="viewDetailsHome(maison.id, listMaison)"
                >
                  {{ maison.user.lastname }} {{ maison.user.firstname }}
                </td>
                <td v-if="!this.verifyIfRoleInclus('Commercial')">
                  <n-space>
                    <n-switch
                    :rail-style="railStyle"
                      v-model:value="maison.id"
                      @update:value="
                        modifierStatutHome('Indisponible', maison.IdHome)
                      "
                    />
                  </n-space>
                </td>
                <td v-if="!this.verifyIfRoleInclus('Commercial')">
                  <div class="d-flex justify-content-center align-items-center gap-2">
                    <button class="btn bg-dark text-light" @click="sellHome(maison.id)">
                      Vendre</button>
                      <!-- v-if="maison.registered_by === 'administrateur'" -->
                      <button class="btn bg-dark text-light  fw-bold" 
                      
                      @click="modifierHome(maison.id)">Modifier</button>
                    <button class="btn bg-danger text-dark fw-bold" 
                    
                     @click="wouldDeleteHome(maison.id)">Supprimer</button>
                  </div>

                </td>
                <td class="text-center">
                  <span
                    class="d-flex justify-content-center align-items-center"
                  >
                    <i
                      class="bi bi-eye mx-2"
                      @click.prevent="
                        viewDetailsHome(maison.id, listMaison)
                      "
                    ></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <section v-show="tabulation == 'indisponible'">
        <div class="col-sm-12 dataTable" v-if="!preloader">
          <table id="MyTableData1" class="table text-center table-responsive">
            <thead>
              <tr class="text-center">
                <th class="bg-light text-center">Maison</th>
                <th class="bg-light text-center">Image</th>
                <th class="bg-light text-center">Prix(Fcfa)</th>
                <th class="bg-light text-center">Située</th>
                <th class="bg-light text-center">Nbre de pièces</th>

                <th class="bg-light text-center">Propriétaire</th>
                <th class="bg-light text-center"  
                v-if="this.verifyIfRoleInclus('Commercial')">Approuvée</th>
                <th class="bg-light text-center">Détail</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(maison, index) in listMaisonEnattente"
                :key="index"
                :class="maison.flag == 1 ? 'bg-warning':null"
              >
                <td
                  @click.prevent="
                    viewDetailsHome(maison.id, listMaisonEnattente)
                  "
                >
                  {{ maison.name }}
                </td>
                <td>
                  <n-image width="50" :src='this.lienUrl.replace("/api/",
                  "/")+"public/public/image/"+maison.photos[0].image'
                  v-if="maison.photos.length" />
                  <span class="badge bg-danger" v-else>x</span>
                </td>
                <td
                  @click.prevent="
                    viewDetailsHome(maison.id, listMaisonEnattente)
                  "
                >
                  {{ moneyFormat.format(maison.cost) }}
                </td>
                <td
                  @click.prevent="
                    viewDetailsHome(maison.id, listMaisonEnattente)
                  "
                >
                  {{ maison.municipality.city.name }}
                  {{ maison.municipality.name }}
                </td>
                <td
                  @click.prevent="
                    viewDetailsHome(maison.id, listMaisonEnattente)
                  "
                >
                  {{ maison.room }}
                </td>

                <td
                  @click.prevent="
                    viewDetailsHome(maison.id, listMaisonEnattente)
                  "
                >
                  {{ maison.user.lastname }} {{ maison.user.firstname }}
                </td>
                <td 
                 v-if="this.verifyIfRoleInclus('Commercial')">
                  <n-space>
                    <n-switch
                      v-model:value="maison.id"
                      @update:value="
                        modifierStatutHome('Disponible', maison.IdHome)
                      "
                    />
                  </n-space>
                </td>
                <td class="text-center">
                  <span
                    class="d-flex justify-content-center align-items-center"
                  >
                    <i
                      class="bi bi-eye mx-2"
                      @click.prevent="
                        viewDetailsHome(maison.id, listMaisonEnattente)
                      "
                    ></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <section v-show="tabulation == 'achetees'">
        <div class="col-sm-12 dataTable" v-if="!preloader">
          <table id="MyTableData2" class="table text-center table-responsive">
            <thead>
              <tr class="text-center">
                <th class="bg-light text-center">Maison</th>
                <th class="bg-light text-center">Image</th>
                <th class="bg-light text-center">Prix(Fcfa)</th>
                <th class="bg-light text-center">Située</th>
                <th class="bg-light text-center">Nbre de pièces</th>

                <th class="bg-light text-center">Propriétaire</th>

                <th class="bg-light text-center">Détail</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(maison, index) in listMaisonAchetees"
                :key="index"
              >
                <td
                  @click.prevent="
                    viewDetailsHome(maison.id, listMaisonAchetees)
                  "
                >
                  {{ maison.name }}
                </td>
                <td>
                  <n-image
                    width="50"
                    :src="
                      this.lienUrl.replace('/api/', '/') +
                      'public/public/image/' + maison.photos[0].image
                    "
                    v-if="maison.photos.length"
                  />
                  <span class="badge bg-danger" v-else>x</span>
                </td>
                <td
                  @click.prevent="
                    viewDetailsHome(maison.id, listMaisonAchetees)
                  "
                >
                  {{ moneyFormat.format(maison.cost) }}
                </td>
                <td
                  @click.prevent="
                    viewDetailsHome(maison.id, listMaisonAchetees)
                  "
                >
                  {{ maison.municipality.city.name }}
                  {{ maison.municipality.name }}
                </td>
                <td
                  @click.prevent="
                    viewDetailsHome(maison.id, listMaisonAchetees)
                  "
                >
                  {{ maison.room }}
                </td>

                <td
                  @click.prevent="
                    viewDetailsHome(maison.id, listMaisonAchetees)
                  "
                >
                  {{ maison.user.lastname }} {{ maison.user.firstname }}
                </td>

                <td class="text-center">
                  <span
                    class="d-flex justify-content-center align-items-center"
                  >
                    <i
                      class="bi bi-eye mx-2"
                      @click.prevent="
                        viewDetailsHome(maison.id, listMaisonAchetees)
                      "
                    ></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
  </div>
</template>
<style scoped>
.trash-two {
  top: 0;
  right: 0.2em;
  background-color: white;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.36) inset;
  padding: 0.2em;
  cursor: pointer;
  border-radius: 5px;
  z-index:99;
}
div.n-space {
  justify-content: center !important;
}

.listes {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.085);
  background-color: white;
  padding: 1em 0;
  border-radius:10px;
}

.lien {
  text-decoration: none;
  font-size: 1.3em;
   
  color: rgb(218, 216, 216);
}
.lien:hover {
  color: rgb(255, 187, 0);;
}
.color,
.colorHistorique,
.colorNew{
  color: rgb(0, 0, 0) !important;
  border-radius:5px;
  padding:.5em;
  font-weight:bold;
  background-color: rgb(255, 187, 0);
}
.text-dark {
  color: black !important;
}
.product .form-group {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.356);
  border-radius: 10px;
}
.bg-info {
  background-color: rgb(0, 0, 0) !important;
}
.addField {
  width: auto !important;
}
.delete {
  position: absolute;
  right: 0;
  cursor: pointer;
  width: 20px;
}
#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: rgba(244, 242, 242, 0);
  display: flex;
  justify-content: center;
  place-items: center;
}
.bg-danger,
.bg-danger:hover {
  color: white !important;
}
strong {
  font-weight: 900;
  font-size: 0.9em !important;
  color: gray;
  text-transform: uppercase;
  padding: 0 0 0.5em 0;
  text-decoration: underline;
}
h6 {
  margin: 0 0 1em 0 !important;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: relative;
  left: 0;
}
[type="radio"]:checked + label:after {
  opacity: 0 !important;
}
.circuit {
  padding: 0 !important;
}
.card {
  padding: 1em 3em;
}
.bg-primary {
  background-color: rgb(55, 137, 167) !important;
  border: 2px solid rgb(55, 137, 167) !important;
  color: white !important;
}
.bi-eye,
.bi-x-circle,
.bi-pencil,
.bi-envelope-paper,
.bi-trash {
  cursor: pointer;
}
.pre {
  padding: 0 0 15em 0;
}
.badge {
  color: white !important;
}
.table {
  border: 1px solid black;
}
td {
  border-right: 2px solid black;
}

.confirmation_modifie {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width: 80%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #d9a509 !important;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.close_modal {
  position: absolute;
  top: 1em;
  right: 1em;
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  z-index: 9999;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
}
.submit-pages {
  padding: 1em 3em;
}
.submit-pages_view {
  padding: 1em 3em;
}
</style>
../../utils