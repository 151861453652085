<script>
const statut = {
    impayer:'bg-danger',
    payer:'bg-success'
}
export default {
    name:"InfoFacturesLocataires",
    props:{
    Allfactures:{type:Array}
    },
    data() {
        return{
            statut:statut
        }
    },
}
</script>
<template>
    <n-modal v-model:show="this.$store.state.showModalFactures">
        <n-card
          style="width: auto"
          :title="`${this.$store.state.facturesLocataires.length} Factures`"
          :bordered="false"
          size="huge"
          role="dialog"
          aria-modal="true"
        >
        <table class="table">
            <thead>
              <tr>
                <th scope="col">Référence</th>
                <th scope="col">Montant (Fcfa)</th>
                <th scope="col">Période</th>
                <th scope="col">Statut</th>
                <th scope="col">Type</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,index) in this.$store.state.facturesLocataires" :key="index">
                <td>{{item.reference}}</td>
                <td>{{item.cost}}</td>
                <td>{{new Date(item.date_debut).toLocaleDateString("fr")}} 
                    au {{new Date(item.date_fin).toLocaleDateString("fr")}} </td>
                <td>
                    <span class="badge" :class="statut[item.statut]">{{item.statut}}</span></td>
                <td>{{item.type}}</td>
              </tr>
            </tbody>
          </table>
        </n-card>
      </n-modal>
</template>
<style scoped>
table thead th {
    background: #6f6d6a !important;
    padding: 10px;
    color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
</style>