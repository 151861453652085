<script>
import LienConnected from "../../views/dashboard/class_help_me";
export default {
  name: "SaveCompUser",
  emits: ["handleSubmit","saveLocataireInHouse"],
  // props:{
  //   isLocataireInHouse:{type:Boolean}
  // },
  data() {
    return {
      lienUrl: new LienConnected().baseUrl,
      utilisateur: {
        nameUtilisateur: "",
        prenomsUtilisateur: "",
        emailUtilisateur: "",
        phoneUtilisateur: "",
        passwordUtilisateur: "",
        roleUtilisateur: "",
        documentUtilisateur: [],
      },
      listRole:[],
      confirmPassword:"",
    };
  },
  methods: {
    middlewareForCreateProprietaire() {
      this.$emit("handleSubmit",this.utilisateur);
    },
    handleMedias(files) {
      this.utilisateur.documentUtilisateur = files;
    },
    saveUser() {
      this.$store.dispatch('handleToogleWindowCreateUser',false) 
    },
    async helpMeForRenderAllList(route) {
      this.preloader = true;
      const dataObject = new LienConnected(route, this.$store.state.token);
      let allList = await dataObject.getAllData();
      if (allList.data.status === true) {
        this.preloader = false;
        return allList.data.data;
      }
    },
    async getAllList() {
      this.listRole = await this.helpMeForRenderAllList("roles");
    },

  },
  created(){
    this.getAllList()
  }
};
</script>
<template>
  <div class="conteneur_modify" v-if="this.$store.state.windowCreateUser">
    <div class="content position-relative">
      <div
        class="ecriteau text-start d-flex justify-content-between
         align-items-center"
      >
        <h4>Enregistrer un utilisateur</h4>
        <i class="bi bi-x-circle close h1" @click.prevent="saveUser"></i>
      </div>
      <div class="col-lg-12 col-md-12 container_color">
        <div class="submit-pages text-start">
          <div>
            <div class="submit-section">
              <div class="row">
                <div class="form-group col-md-6">
                  <label class="text-dark"
                    >Nom <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="utilisateur.nameUtilisateur"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label class="text-dark"
                    >Prénoms <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="utilisateur.prenomsUtilisateur"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label class="text-dark"
                    >Email <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="utilisateur.emailUtilisateur"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label class="text-dark"
                    >Téléphone <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="utilisateur.phoneUtilisateur"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label class="text-dark"
                    >Mot de passe <span class="text-danger">*</span></label
                  >
                  <span
                    class="text-danger mx-2"
                    v-if="
                      confirmPassword &&
                      utilisateur.passwordUtilisateur != confirmPassword
                    "
                    >Mot de passe incorrect</span
                  >
                  <input
                    type="password"
                    class="form-control"
                    v-model="utilisateur.passwordUtilisateur"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label class="text-dark"
                    >Confirmation du Mot de passe
                    <span class="text-danger">*</span></label
                  >
                  <input
                    type="password"
                    class="form-control"
                    v-model="confirmPassword"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label class="text-dark">Role</label>

                  <v-select
                    v-model="utilisateur.roleUtilisateur"
                    :searchable="true"
                    :options="listRole"
                    multiple
                    label="label"
                  >
                    <template>
                      <p>{{ label }}</p>
                    </template>
                  </v-select>
                </div>
                <div class="form-group col-md-6 text-start">
                  <label class="text-dark"
                    >Pièces jointes <span class="text-danger">*</span></label
                  >
                  <UploadMedias
                    uploadMsg="glisser et déposer vos images"
                    @changed="handleMedias"
                  />
                </div>

                <div
                  class="text-start mt-3"
                  v-if="
                    utilisateur.passwordUtilisateur &&
                    utilisateur.emailUtilisateur &&
                    utilisateur.nameUtilisateur &&
                    utilisateur.prenomsUtilisateur
                  "
                >
                  <button
                    class="btn bg-dark text-light rounded"
                    @click.prevent="middlewareForCreateProprietaire"
                  >
                    Enregistrer
                    <span
                      v-if="this.$store.state.isLoadingSpinner"
                      class="spinner-border spinner-border-sm ms-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.cont .form-group {
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.222);
  border-radius: 8px;
  background-color: black;
  color: white !important;
}
.submit-pages {
  padding: 1em 3em;
}
.container_color {
  background: #d9a509 !important;
}
.bi-x-circle{
  cursor: pointer;
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  z-index: 15;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
}
.conteneur_modify .content {
  width: 80%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #d9a509 !important;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
</style>
