<script>
const StatutColor = {
    PENDING:'bg-info',
    SUCCESS:'bg-success'
}
export default {
  name: "DetailPaiement",
  data() {
    return {StatutColor:StatutColor};
  },
};
</script>
<template>
    <h3 class="text-center">Facture : {{this.$store.state.factureReference}}</h3>
  <table v-if="this.$store.state.detailArrayPaiement.length">
    <tr style="background:gray">
      <th>Date</th>
      <th>Moyen de paiement</th>
      <th>Montant (Fcfa)</th>
      <th>Transaction</th>
      <th>Statut</th>
    </tr>
    <tr v-for="(item,index) in this.$store.state.detailArrayPaiement" :key="index">
      <td>
        {{new Date(item.created_at).toLocaleDateString('fr')}}
      </td>
      <td>
        {{item.channels}}
      </td>
      <td>
        {{item.amount}}
      </td>
     
      <td>
        {{item.trans_id}}
      </td>
      <td>
        <span class="badge" :class="StatutColor[item.status]">{{item.status}} </span>
      </td>
    </tr>
    
    
  </table>
  <div style="display:flex;padding:2em" 
  v-if="!this.$store.state.detailArrayPaiement.length">
    Pas de donnée
  </div>
</template>
<style scoped>
th{
  background-color:gray !important;
  color:white;
}
table, th, td {
  border: 1px solid black;
 
  border-collapse: collapse;
}
td,th{
  padding: 1em;
}
</style>
